import { RequestWithAndroid } from "./android";

const XTREAM = {

    host: "",
    username: "",
    password: "",

    api_url: "",
    channel_url: "",
    epg_url: "",
    movie_url: "",
    series_url: "",

    server_info: {},
    user_info: {},

    stream_format: "",

    requests: [],

    resetInfo: function () {
        XTREAM.host = "";
        XTREAM.username = "";
        XTREAM.password = "";
        XTREAM.api_url = "";
        XTREAM.channel_url = "";
        XTREAM.epg_url = "";
        XTREAM.movie_url = "";
        XTREAM.server_info = {};
        XTREAM.user_info = {};
        XTREAM.stream_format = "";
    },

    abortAll: function () {

        for (var i = 0; i < XTREAM.requests.length; i++) {
            try {
                XTREAM.requests[i].aborted = true;
                XTREAM.requests[i].abort();
            } catch (e) { }
        }

        XTREAM.requests = [];

    },

    isXtreamPlaylist: function (url) {
        return XTREAM.is_xtream_url(url) || XTREAM.is_xui_one_url(url);
    },

    is_xtream_url: function (url) {

        if (!url || url.indexOf("/get.php?") == -1) return false;

        let [host, query] = url.split("/get.php?");

        if (!host || !query) return false;

        query = query.split("&");

        let queryObj = {};

        for (var i = 0; i < query.length; i++) {
            let [key, value] = query[i].split("=");
            queryObj[key] = value;
        }

        let { username, password } = queryObj;

        if (!username || !password) return false;

        return { host, username, password };

    },

    is_xui_one_url: function (url) {

        if (!url) return false;

        let key = "";

        if (url.indexOf("/playlist/") != -1) {
            key = "/playlist/";
        } else if (url.indexOf("/m3u-ts/") != -1) {
            key = "/m3u-ts/";
        } else {
            return false;
        }

        let [host, query] = url.split(key);
        if (!host || !query) return false;

        var [username, password, lastPart] = query.split("/");
        if (!username || !password) return false;

        if (key == "/playlist/" && (!lastPart || lastPart.indexOf("m3u") == -1)) return false;

        return { host, username, password };

    },

    init: function (xtream_account = {}, account = {}) {

        let { host, username, password } = xtream_account;

        let user_info = account.user_info || {};
        let server_info = account.server_info || {};
        let serverProtocol = server_info.server_protocol;

        let hostProtocol = host.split("://")[0];

        if (serverProtocol && hostProtocol && hostProtocol != serverProtocol) {
            host = host.replace(hostProtocol + "://", serverProtocol + "://");
        }

        XTREAM.host = host || "";
        XTREAM.username = username || "";
        XTREAM.password = password || "";

        XTREAM.api_url = `${host}/player_api.php?username=${username}&password=${password}`;

        XTREAM.epg_url = `${host}/timeshift/${username}/${password}/`;
        XTREAM.movie_url = `${host}/movie/${username}/${password}/`;
        XTREAM.series_url = `${host}/series/${username}/${password}/`;

        XTREAM.user_info = user_info;
        XTREAM.server_info = server_info;

    },

    checkUserAccount: function (account) {

        if (!account) return { error: true, message: "Invalid account" };

        let { user_info } = account;

        if (!user_info) return { error: true, message: "Playlist is not active" };

        let { status, auth, exp_date } = user_info;

        if (auth === 0) return { error: true, message: "Incorrect userneme or password" };

        if (!status || !auth) return { error: true, message: "Playlist is not active" };

        status = status.toLowerCase();

        if (status == "disabled") return { error: true, message: "Playlist is disabled" };

        if (status == "expired") return { error: true, message: "Playlist is expired" };

        if (exp_date && new Date(exp_date * 1000) < new Date()) return { error: true, message: "Playlist is expired" };

        if (status != "active") return { error: true, message: "Playlist is not active" };

        return { error: false, message: "success" };
    },

    login: function (xtream_account) {

        return new Promise(async (resolve, reject) => {

            if (!xtream_account) return reject("errorLogout");

            let { host, username, password } = xtream_account;

            let login_url = `${host}/player_api.php?username=${username}&password=${password}`;

            try {
                var account = await XTREAM.request(login_url);
           
                
            } catch (e) {
           
                
                return reject("errorLogout",e);
            }

            let { error, message } = XTREAM.checkUserAccount(account);

            if (error) return reject(message);

            resolve(account);

        });

    },

    getChannels: function () {
        return XTREAM.request(XTREAM.api_url + "&action=get_live_streams");
    },

    getChannelCategories: function () {
        return XTREAM.request(XTREAM.api_url + "&action=get_live_categories");
    },

    getChannelUrl: function (channel) {

        const { host, username, password } = XTREAM;

        let { id, stream_type } = channel;

        if (stream_type == "radio_streams") {
            return `${host}/${username}/${password}/${id}`;
        } else {

            switch (XTREAM.stream_format) {
                case "ts":
                    return `${host}/live/${username}/${password}/${id}.ts`;
                case "m3u8":
                    return `${host}/live/${username}/${password}/${id}.m3u8`;
                // case "rtmp":
                //     return `rtmp://${ server_url }:${ rtmp_port }/live/${ username }/${ password }/${ id }`;
                default:
                    return `${host}/${username}/${password}/${id}`;
            }

        }

    },

    getChannelEpg: function (id) {

        let key = "getEpgReq";

        if (XTREAM[key]) {
            XTREAM[key].aborted = true;
            XTREAM[key].abort();
            XTREAM[key] = null;
        }

        return XTREAM.request(XTREAM.api_url + "&action=get_simple_data_table&stream_id=" + id, key);
    },

    getEpgUrl: function (channelId, start, duration) {
        return `${XTREAM.epg_url}${duration}/${start}/${channelId}.ts`
    },

    getMovies: function () {
        return XTREAM.request(XTREAM.api_url + "&action=get_vod_streams");
    },

    getMovieCategories: function () {
        return XTREAM.request(XTREAM.api_url + "&action=get_vod_categories");
    },

    getMovieUrl: function (id, ext) {
        return XTREAM.movie_url + id + (ext ? ("." + ext) : "");
    },

    getMovieInfo: function (id) {
        return XTREAM.request(XTREAM.api_url + "&action=get_vod_info&vod_id=" + id);
    },

    getSeries: function () {
        return XTREAM.request(XTREAM.api_url + "&action=get_series");
    },

    getSeriesCategories: function () {
        return XTREAM.request(XTREAM.api_url + "&action=get_series_categories");
    },

    getSeriesUrl: function (episodeId, ext) {
        return XTREAM.series_url + episodeId + (ext ? ("." + ext) : "");
    },

    getSeriesInfo: function (series_id) {
        return XTREAM.request(XTREAM.api_url + "&action=get_series_info&series_id=" + series_id);
    },

    request: function (url, key, checkedProtocol = false) {

        return new Promise((resolve, reject) => {

            let request = XTREAM.httpRequest;

            if (window.Android) request = XTREAM.androidRequest;

            request(url, key)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {

                    if (checkedProtocol) {
                        reject(error);
                    } else {

                        let protocol = url.split("://")[0];

                        if (protocol == "http") {
                            url = url.replace("http://", "https://");
                        } else if (protocol == "https") {
                            url = url.replace("https://", "http://");
                        }

                        XTREAM.request(url, key, true)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });

                    }

                })

        });

    },

    httpRequest: function (url, key) {

        const req = new XMLHttpRequest();

        const promise = new Promise((resolve, reject) => {

            if (!window.navigator.onLine) return reject("network disconnected");

            req.timeout = 10000;

            req.onreadystatechange = function () {

                if (req.readyState > 1) req.timeout = 180000;

                if (req.aborted || req.readyState != 4) return;

                XTREAM.requests = XTREAM.requests.filter(r => r != req);

                if ((req.status >= 200 && req.status < 300)) {

                    try {
                        let response = JSON.parse(req.responseText);
                        resolve(response);
                    } catch (e) {
                        reject("request error");
                    }

                } else {
                    reject("request error");
                }

            }

            req.ontimeout = function () {

                if (req.aborted) return;

                XTREAM.requests = XTREAM.requests.filter(r => r != req);
                reject("request timeout");

            }

            req.open("get", url);

            req.send();

        });

        XTREAM.requests.push(req);

        if (key) XTREAM[key] = req;

        return promise;

    },

    androidRequest: function (url, key) {

        let req = RequestWithAndroid(url);

        XTREAM.requests.push(req);

        if (key) XTREAM[key] = req;

        return req;

    }

}

export default XTREAM;