import { generateMacAddress, generateRandomDeviceId, loadScript } from "@/src/utils/util";
import Storege from "../storage/storage";

class Tizen {

    id = "";
    mac = "";
    model = "tizen";
    version = "";

    constructor() { }

    async init () {

        try {
            await loadScript("$WEBAPIS/webapis/webapis.js");
        } catch (e) {
            console.log(e);
        }

        try {

            window.tizen.tvinputdevice.registerKeyBatch(
                [
                    "0", "1", "2", "3", "4",
                    "5", "6", "7", "8", "9",
                    "ChannelUp",
                    "ChannelDown",
                    "MediaRewind",
                    "MediaFastForward",
                    "MediaPause",
                    "MediaPlay",
                    "MediaStop",
                    "MediaTrackPrevious",
                    "MediaTrackNext",
                    "MediaPlayPause",
                    "ColorF0Red",
                    "ColorF1Green",
                    "ColorF2Yellow",
                    "ColorF3Blue",
                    "ChannelList"
                ]);

        } catch (e) { }

        return new Promise((resolve, reject) => {

            let deviceId = Storege.getDeviceId();

            try {
                if (!deviceId) deviceId = window.webapis.productinfo.getDuid();
            } catch (e) { }

            if (!deviceId) deviceId = generateRandomDeviceId();

            Storege.setDeviceId(deviceId);

            let mac = Storege.getMac();

            try {
                if (!mac) mac = window.webapis.network.getMac();
            } catch (e) { }

            if (!mac) mac = generateMacAddress(deviceId);

            Storege.setMac(mac);

            let version = "0.0.0";

            try {
                version = window.webapis.tvinfo.getVersion();
            } catch (e) { }

            this.id = deviceId;
            this.mac = mac;
            this.version = version;

            resolve();

        });
    }

    setDataInFileSysytem () {

        // return new Promise((resolve, reject) => {



        //     try {
        //         window.webapis.file.openFile("wgt-private", "data.json", "rw");
        //         window.webapis.file.writeAll("wgt-private", "data.json", JSON.stringify({channelsContent}));
        //         window.webapis.file.closeFile("wgt-private", "data.json");
        //         resolve();
        //     } catch (e) {
        //         reject(e);
        //     }


        // })
    }
    // get saved data from file system
    getSetDataFromSistem({name, data}) {

        

        
        const createFile =  async ()=>{

          return new Promise((resolve, reject) => {

                tizen.filesystem.resolve('documents/dream4k',  (res)=> {
        
                            
                    res.createFile(name)

                    if(res){

                        res.openStream('w', (stream)=> {
                                
                                stream.write(JSON.stringify(data));
                                
                                stream.close();
                        })
                    }
                
                    resolve(true);
                },(err)=>{
                    console.log(err);
                    resolve(false);
                })
        
            });

        }
        
        return new Promise(  async (resolve, reject) => {
         
            try {

                let dir = 'documents/dream4k';
        
                tizen.filesystem.resolve(dir,  async (res)=> {
                    
                    
                    res.listFiles( async (files)=> {
                        

                        let file = null;
                 
                  
                        for (var i = 0; i < files.length; i++) {

                                
                            if (files[i].name == name) {
                         
                                
                                file = files[i]
                         
                            }else{
                                res.deleteFile(files[i].fullPath)
                            }

                        }
               
                        
                        if(file){
                         
                            file.openStream('rw', (stream)=> {

                                
                                let content = null;

                                if(data){
                               
                                    stream.write(JSON.stringify(data));
                              
                                }else if(file.fileSize){
                                 
                                    content = stream.read(file.fileSize);

                                }

                                stream.close();
                                
                                resolve(content ? JSON.parse(content) : '');


                            },(err)=> {
                                resolve('');
                            })

                            return;

                        }else{

                           await createFile()
                      
                        }

                        resolve('');
        
                    },(err)=>{
                        resolve('');
                    })
        
                },async (err)=>{

                    tizen.filesystem.resolve('documents', function (res) {

                        res.createDirectory('dream4k')    
                
                    });
                                

                    resolve('');

                })
        
        
            } catch (e) {
                    console.log('error', e);
                    
                    resolve('');
           
            }

        });

    }


    // delete data from file system
    deleteDataFromSistem({name}) {


        return new Promise((resolve, reject) => {

            try {

                tizen.filesystem.resolve('documents/dream4k',  (res)=> {
                    
                    res.listFiles( async (files)=> {
                        
                        for (var i = 0; i < files.length; i++) {

                            if (files[i].name == name) {
                                res.deleteFile(files[i].fullPath)
                            }

                        }

                        resolve(true);
        
                    })
        
                },(err)=>{
                    console.log(err);
                    resolve(false);
                })
        
            } catch (e) {
                console.log('error', e);
                resolve(false);
            }

        });
    }


    exit () {
           

        try {
            window.tizen.application.getCurrentApplication().exit();
        } catch (e) {
            window.close();
        }
    }

}

export default Tizen;