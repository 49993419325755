import { useEffect, useState, createContext, memo } from "react";
import { createPortal } from "react-dom";
import Text from "@/src/components/text/Text";
import useKeydown from "@/src/hooks/useKeydown";
import AudioItem from "./AudioItem";
import ListView from "@/src/components/listView/ListView";
import "./AudioPopup.scss";

export const AudioContext = createContext();

const AudioPopup = ({
    audios,
    isVisible,
    onBack = () => { }
}) => {

    const [isShowing, setIsShowing] = useState(false);

    const [selected, setSelected] = useState(0);

    useEffect(() => {

        if (isVisible) {

            for (let i = 0; i < audios.length; i++) {

                if (audios[i].enabled) {
                    setSelected(i);
                    break;
                }

            }

            setTimeout(() => {
                setIsShowing(true);
            }, 0);

        } else {
            setIsShowing(false);
        }

    }, [isVisible]);

    useKeydown({
        isActive: isVisible,
        back: () => {
            onBack();
        }
    });

    if (!isVisible) return null;

    return createPortal(
        <AudioContext.Provider
            value={{
                audios,
                selected,
                setSelected,
            }}
        >
            <div
                className={"audio-popup " + (isShowing ? "show" : "")}
                onMouseDown={e => e.stopPropagation()}
                onMouseEnter={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
                onMouseMove={e => e.stopPropagation()}
            >

                <div className="audio-popup-content">

                    <div className="audio-popup-title">
                        <Text>audio</Text>
                    </div>

                    <div className="audio-popup-list">

                        <div className="audio-popup-list-col">
                            <ListView
                                id={audios}
                                uniqueKey={"audio-popup-list"}
                                listType="vertical"
                                itemsTotal={audios.length}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={0}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={AudioItem}
                                itemWidth={40}
                                itemHeight={7.7}
                                isActive={true}
                            />
                        </div>

                    </div>

                </div>

            </div>
        </AudioContext.Provider>
        , document.getElementById("root"))

};

export default memo(AudioPopup);