

const DreamRequest = {

    // host: 'http://app-v15-dream4k.info/d4k/4k/API-V3.php',
    host: "http://app-v15-dream4k.info/d4k/API-V4.php",

    url: async (data) => {


        let url = `${DreamRequest.host}?${DreamRequest.setQuery(data)}`

        try {

            let res = await DreamRequest.request('GET', url)

            return res

        } catch (e) {

            return e
        }


    },


    setQuery: (data) => {

        let query = ''

        for (const key in data) {

            query += `${key}=${data[key]}&`

        }

        query.split(0, query.length - 1)

        return query

    },

    request: (method, url) => {


        return new Promise((resolve, reject) => {

            let xhr = new XMLHttpRequest();

            xhr.open(method, url);

            xhr.onload = () => {


                try {

                    let resp = JSON.parse(xhr.responseText)


                    if (xhr.status >= 200 && xhr.status < 300) {

                        if (resp.status == 103 || resp.status == 404)
                            reject({ err: true, message: resp.message })
                        else
                            resolve(resp);

                    } else {



                        let err = resp.message || 'error'

                        reject({ err: true, message: err })

                    }

                } catch (e) {
                    reject({ err: true, message: "error" })
                }

            };

            xhr.onerror = () => reject({ err: true, message: "error" })

            xhr.onabort = () => reject({ err: true, message: "aborted" })

            xhr.ontimeout = () => reject({ err: true, message: "timeout" })

            xhr.send();


        })

    }

}

export default DreamRequest;