import { memo } from "react";
import Text from "../text/Text";
import { FormatDate } from "../../utils/util";
import useStore from "../../hooks/useStore";
import "./FooterInfo.scss";
import Config from "../../config";

const FooterInfo = () => {

    const [deviceInfo] = useStore("deviceInfo");
    const [playlistInfo] = useStore("playlistInfo");
    const [rtlMode] = useStore("rtlMode");

    const AppVersion = () => {

        return rtlMode ? <div>{Config.APP_VERSION} :<Text>version</Text></div> :
            <div><Text>version</Text>: {Config.APP_VERSION}</div>

    };

    const AppStatus = () => {

        if (deviceInfo && deviceInfo.payed) {

            // return <div><Text>status</Text>: <span><Text>activated</Text></span></div>
            return rtlMode ? <div><Text>activated</Text> :<Text>status</Text></div> :
                <div><Text>status</Text>: <Text>activated</Text></div>

        } else if (deviceInfo && deviceInfo.free_trial_expired) {

            if (new Date(deviceInfo.free_trial_expired).getTime() < new Date().getTime()) {

                return <div> <Text>free trial expired</Text></div>

            } else {

                let expired_date = FormatDate(deviceInfo.free_trial_expired, "dd.MM.yyyy hh:mm");

                // return <div> <Text>free trial expired</Text>: <span>{expired_date}</span></div>
                return rtlMode ? <div>{expired_date} :<Text>free trial expired</Text></div> :
                    <div><Text>free trial expired</Text>: {expired_date}</div>

            }

        }

        return null;

    };

    const MacAddress = () => {
        // return <div><Text>mac address</Text>: <span>{deviceInfo?.mac || ""}</span></div>

        return rtlMode ? <div>{deviceInfo?.mac || ""} :<Text>mac address</Text></div> :
            <div><Text>mac address</Text>: <span>{deviceInfo?.mac || ""}</span></div>
    };

    const WebPage = () => {
        // return <div> <Text>web page</Text>: <span>{Config.Web_host}</span></div>
        return rtlMode ? <div>{Config.Web_host} :<Text>web page</Text></div> :
            <div><Text>web page</Text>: {Config.Web_host}</div>
    }

    const PlaylistInfo = () => {

        let { exp_date = 0 } = playlistInfo;

        if (!exp_date) return null;

        let expired_date = FormatDate((+exp_date * 1000), "dd.MM.yyyy hh:mm");

        return <div>
            <Text>playlist expired date</Text>: <span>{expired_date || ""}</span>
        </div>
    }

    return (
        <div className="footer-info">

            <div className="column">

                <AppVersion />
                <MacAddress />
                <PlaylistInfo />

                {/* <AppStatus /> */}
            </div>

            <div className="column">

            </div>

        </div>
    )

};

export default memo(FooterInfo);