import { useEffect, useState } from "react";
import useStore from "./useStore";
import { emptyContent } from "../services/content/emptyContent";

import Text from "../components/text/Text";

const useContent = (type) => {

    const [content, setContent] = useStore(type); // channelsContent, moviesContent, seriesContent

    const [filteredContent, setFilteredContent] = useState(null);

    // filter hidden categories and items
    useEffect(() => {

        if (!content) return console.warn("content is null");

        if (filteredContent && filteredContent.status == content.status) return; // prevent infinite loop

        let { categories_arr, categories_obj, items_arr, items_obj, status } = content;

        let filtered_categories_arr = [];

        let filtered_categories_obj = { ...categories_obj };

        let filtered_items_arr = [];

        let filtered_items_obj = { ...items_obj };

        for (let i = 0; i < categories_arr.length; i++) {

            let id = categories_arr[i];

            if (categories_obj[id].isHidden) continue;

            filtered_categories_arr.push(id);

        }

        for (let i = 0; i < items_arr.length; i++) {

            let id = items_arr[i];

            let { category_id } = items_obj[id];

            if (categories_obj[category_id].isHidden) continue;

            filtered_items_arr.push(id);

        }

        if (filtered_categories_obj["all"]) {
            filtered_categories_obj["all"].items = filtered_items_arr;
        }

        if (categories_obj["all"]) categories_obj["all"].name = <Text>all</Text>
        if (categories_obj["favorites"]) categories_obj["favorites"].name = <Text>favorites</Text>
        if (categories_obj["search"]) categories_obj["search"].name = <Text>search</Text>
        if (categories_obj["continue"]) categories_obj["continue"].name = <Text>continue watching</Text>
        if (categories_obj["Other"]) categories_obj["Other"].name = <Text>Other</Text>


        setFilteredContent({
            categories_arr: filtered_categories_arr,
            categories_obj: filtered_categories_obj,
            items_arr: filtered_items_arr,
            items_obj: filtered_items_obj,
            status
        });

    }, [content, filteredContent]);

    const onChange = (newContent) => {

        setFilteredContent(newContent);

    };

    return [filteredContent || { ...emptyContent, status: "null" }, onChange];

};

export default useContent;