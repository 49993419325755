import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { emptyContent } from "../content/emptyContent";

const initialState = {
    platform: null,
    deviceInfo: null,
    token: null,
    language: "en",
    playlists: [],
    playlist: null,
    streamFormats: [],
    channelsContent: { ...emptyContent },
    moviesContent: { ...emptyContent },
    seriesContent: { ...emptyContent },
    documentIsHidden: false,
    isOnline: true,
    rtlMode: false,
    playlistInfo: {},
    servers: {},
    subtitleSettings: { size: "medium", style: "drop-shadow" },
    appBackground: "fullscreen", // "fullscreen", "croped", "none
};

const globalState = (state = initialState, action) => {

    switch (action.type) {
        case "platform":
            return { ...state, platform: action.payload };
        case "deviceInfo":
            return { ...state, deviceInfo: action.payload };
        case "token":
            return { ...state, token: action.payload };
        case "rtlMode":
            return { ...state, rtlMode: action.payload };
        case "language":
            return { ...state, language: action.payload };
        case "playlists":
            return { ...state, playlists: action.payload };
        case "playlist":
            return { ...state, playlist: action.payload };
        case "channelsContent":
            return { ...state, channelsContent: action.payload };
        case "moviesContent":
            return { ...state, moviesContent: action.payload };
        case "seriesContent":
            return { ...state, seriesContent: action.payload };
        case "streamFormats":
            return { ...state, streamFormats: action.payload };
        case "documentIsHidden":
            return { ...state, documentIsHidden: action.payload };
        case "isOnline":
            return { ...state, isOnline: action.payload };
        case "subtitleSettings":
            return { ...state, subtitleSettings: action.payload };
        case "appBackground":
            return { ...state, appBackground: action.payload };
        case "playlistInfo":
            return { ...state, playlistInfo: action.payload };
        default:
            return state;

    }

};

window.initialState = initialState;

const store = createStore(combineReducers({ globalState }), {}, applyMiddleware(thunk));

export default store;
