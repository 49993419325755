import { memo, useEffect, useRef, useState } from "react";
import Text from "../text/Text";
import KeyboardLarge from "../keyboard/KeyboardLarge";
import "./Input.scss";

const Input = ({
    placeholder,
    initialValue = "",
    isFocused = false,
    maxLength = 255,
    onBlur = () => { },
    onDone = () => { },
    onChange = () => { },
    onTop = () => { },
    onBack = () => { },
    helpKeys = [],
    keyboardClassName = "",
}) => {

    const parentRef = useRef();
    const inputRef = useRef();

    const [value, setValue] = useState(initialValue);

    const options = {
        onDone: () => {
            onDone(value);
        },
        onBack: () => {
            onBack(value);
        },
        onTop: () => {
            onTop(value);
        },
        onBackspace: () => {
            if (value.length == 0) return;
            setValue(value.slice(0, -1));
        },
        onClear: () => {
            setValue("");
        },
        onClose: () => {
            onBlur(value);
        },
        onChange: (char) => {
            if (value.length >= maxLength) return;
            setValue(value + char);
        },
        helpKeys,
    };

    useEffect(() => {
        onChange(value);
    }, [value]);

    useEffect(() => {

        let parent = parentRef.current;
        let input = inputRef.current;

        if (!parent || !input) return;

        if (isFocused) {

            let offset = 0;

            let width = input.offsetWidth;
            let parentWidth = parent.offsetWidth;

            if (width > parentWidth) {
                offset = width - parentWidth;
            }

            input.style.transform = "translateX(-" + (offset) + "px)";
            input.style["-webkit-transform"] = "translateX(-" + (offset) + "px)";

        } else {
            input.style.transform = "translateX(0px)";
            input.style["-webkit-transform"] = "translateX(0px)";
        }


    }, [value, isFocused]);

    useEffect(() => {

        const past = (e) => {
            setValue(value => value + e.clipboardData.getData('text/plain'));
        }

        if (isFocused) {
            document.addEventListener('paste', past);
        } else {
            document.removeEventListener('paste', past);
        }

        return () => {
            document.removeEventListener('paste', past);
        }

    }, [isFocused]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <>
            <div className="input-parent">
                <div className={"custom-input" + (isFocused ? " focused" : "")} ref={parentRef}>
                    <div className="value" ref={inputRef}>{value.split(" ").join(String.fromCharCode(160))}</div>
                    {
                        !value &&
                        <div className="placeholder">
                            <Text>{placeholder}</Text>
                        </div>
                    }
                </div>
            </div>
            {isFocused && <KeyboardLarge options={options} className={keyboardClassName} />}
        </>
    )

}

export default memo(Input);