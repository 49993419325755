import { memo, useContext } from "react";
import { MoviesPageContext } from "../../MoviesPage";
import Text from "../../../../components/text/Text";
import { searchWhite } from "../../../../assets/icons";

const MoviesSectionTitle = () => {

    const {
        moviesContent,
        currentCategoryId,
        searchIsOpen,
        searchQuery
    } = useContext(MoviesPageContext);

    const category = moviesContent.categories_obj[currentCategoryId];

    let title = category?.name;

    if (searchIsOpen) {

        if (searchQuery) {
            title = <span>{searchQuery}</span>;
        } else {
            title = <span><Text>search</Text></span>;
        }

        title = <> <img src={searchWhite} /> {title}</>

    }

    return (
        <div className={"movies-section-title " + (searchIsOpen ? "search-is-open" : "")}>
            {title}
        </div>
    )

};

export default memo(MoviesSectionTitle);