import { memo, useContext, useEffect, useRef, useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { MovieInfoContext } from "../movieInfo/MovieInfo";
import useKeydown from "@/src/hooks/useKeydown";
import useStore from "@/src/hooks/useStore";
import PageLoading from "@/src/components/pageLoading/PageLoading";
import SUBTITLES from "@/src/services/api/subtitles";
import PlayNextEpisode from "./popups/playNextEpisode/PlayNextEpisode";
import ProgressContainer from "./components/ProgressContainer";
import ButtonsContainer from "./components/ButtonsContainer";
import ContinuePopup from "./popups/continue/ContinuePopup";
import SubtitlePopup from "./popups/subtitle/SubtitlePopup";
import SubtitleContainer from "./components/SubtitleContainer";
import SubtitleSettings from "./popups/subtitleSettings/SubtitleSettings";
import AudioPopup from "./popups/audio/AudioPopup";
import PlayerError from "@/src/components/playerError/PlayerError";
import "./MoviePlayer.scss"
import AppPlayer from "@/src/components/appPlayer/AppPlayer";
import { MoviesPageContext } from "../MoviesPage";
import { MenuMoviesContext } from "../../menu/MenuContent";

let controlesTimeout = null;
let seekToTimeout = null;

const MoviePlayer = ({ contextType = 'movie' }) => {

    const {
        currentBlock,
        setCurrentBlock,
        playerOptions,
        setPlayerOptions,
        setContinueData,
        movieInfo
    } = useContext(MovieInfoContext);

    const { isLockedMovie, afterMovieInfo } = useContext(contextType == 'movie' ? MoviesPageContext : MenuMoviesContext);

    const isActive = currentBlock === "player";

    const playerRef = useRef(null);

    const [documentIsHidden] = useStore("documentIsHidden");

    const [isOnline] = useStore("isOnline");

    const [playerCurrentBlock, setPlayerCurrentBlock] = useState("progressContainer");

    const [url, setUrl] = useState("");

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);

    const [isPlaying, setIsPlaying] = useState(false);

    const [isPaused, setIsPaused] = useState(false);

    const [isSeeking, setIsSeeking] = useState(false);

    const [cancelNextEpisode, setCancelNextEpisode] = useState(false);

    const [controlesVisible, setControlesVisible] = useState(false);

    const [audios, setAudios] = useState([]);

    const [subtitles, setSubtitles] = useState([]);

    const [subtitle, setSubtitle] = useState(null);

    const loadTracks = () => {

        let subtitles = [];

        try {
            setAudios(playerRef.current.getInternalPlayer().audioTracks || []);
        } catch (e) { }

        try {

            let tizenTextTracks = playerRef.current.getInternalPlayer().tizenTextTracks || [];

            if (tizenTextTracks.length) subtitles = [{ lng: "Embedded", subtitles: tizenTextTracks }];

        } catch (e) { }

        searchSubtitles((response) => {

            subtitles = [...subtitles, ...response];

            if (subtitles.length) subtitles.unshift({ lng: "Off", subtitles: [] });

            setSubtitles(subtitles);

        });

    }

    const playerConfign = {
        controls: false,
        width: "100%",
        height: "100%",
        url: url,
        mediatype: playerOptions?.type,
        playing: isPlaying && isOnline && !documentIsHidden && playerCurrentBlock != "settingsPopup",
        onReady: () => {
            setLoading(false);
        },
        onPlay: () => {
            if (!isOnline || documentIsHidden) return;
            setError(false);
            setLoading(false);
            setIsPaused(false);
            setIsSeeking(false);
        },
        onPause: () => {
            if (!isOnline || documentIsHidden) return;
            setLoading(false);
            setIsPaused(true);
        },
        onEnded: () => {

            let { type, episode } = playerOptions;

            if (type == "episode") {

                if (cancelNextEpisode || !episode?.next_episode) {
                    playerBack();
                }

            } else {
                playerBack();
            }

        },
        onBuffer: () => {
            // setLoading(true);
        },
        onBufferEnd: () => {
            setLoading(false);
        },
        onStart: () => {
            setLoading(false);
            setIsPaused(false);
        },
        onError: (e) => {
            if (playerConfign.mediatype != "trailer") setError(true);
            setLoading(false);
        },
        onDuration: (sec) => {
            setDuration(sec);
            loadTracks();
        },
        onProgress: ({ playedSeconds }) => {

            setError(false);

            setLoading(false);

            if (isSeeking) return;

            if (playerOptions && playerOptions.percent > 0) return;

            if (playerOptions && playerOptions.askToContinue) return;

            setCurrentTime(playedSeconds);

            let { type, episode } = playerOptions;

            if (type == "episode" && duration > 0 && playedSeconds > 0 && isPlaying) {

                if (duration - playedSeconds > 60) return;

                if (!episode || !episode.next_episode) return;

                if (cancelNextEpisode || playerCurrentBlock == "play-next-episode") return;

                setPlayerCurrentBlock("play-next-episode");

            }

        },
        onSeek: () => {
            setLoading(false);
            setIsSeeking(false);
        },
        onLoadedTracks: () => {
            loadTracks();
        },
        config: {
            youtube: {
                playerVars: {
                    showinfo: 1,
                    controls: 0,
                    loop: 0,
                    disablekb: 1,
                    autoplay: 1,
                }
            }
        }
    }

    const searchSubtitles = (cb) => {

        let { type, episode } = playerOptions;

        if (type != "movie" && type != "episode") return;

        if (type == "episode" && !episode) return;

        let params = {};

        if (type == "movie") {

            params.type = "movie";

            if (movieInfo.tmdbId) {
                params.tmdb_id = movieInfo.tmdbId;
            } else {
                params.query = movieInfo.name;
                params.year = movieInfo.year;
            }

        } else {

            params.type = "episode";

            if (movieInfo.tmdbId) {
                params.tmdb_id = movieInfo.tmdbId;
            } else {
                params.query = movieInfo.name;
                params.year = movieInfo.year;
            }

            params.episode_number = episode.num;
            params.season_number = episode.season;

        }

        if (params.query) {
            params.query = params.query.split(" ").join("+");
            params.query = params.query.toLowerCase();
        }

        SUBTITLES.search(params, cb);

    }

    const playPause = () => {
        toggleControls();
        setIsPlaying(!isPlaying);
    }

    const seekTo = (percent, seconds) => {

        toggleControls();

        let player = playerRef.current;

        if (!player || duration == 0) return;

        if (seconds != null && seconds != undefined) {
            var currentTime = seconds;
        } else {
            var currentTime = percent * duration;
        }

        if (currentTime < 0) currentTime = 0;

        if (currentTime > duration) currentTime = duration;

        if (currentTime == duration) currentTime -= 1;

        if (isNaN(currentTime)) return;

        setCurrentTime(currentTime);

        setIsSeeking(true);

        clearTimeout(seekToTimeout);

        seekToTimeout = setTimeout(() => {

            setLoading(true);

            player.seekTo(currentTime, "seconds");

            if (playerOptions && playerOptions.percent > 0) {
                setPlayerOptions({ ...playerOptions, percent: 0 });
            }

        }, 1000);

    }

    const saveToContinue = () => {

        if (playerOptions) {

            if (isLockedMovie(playerOptions.movie.id, afterMovieInfo)) return;

            let { type, episode } = playerOptions; // type => "movie" | "episode"

            if (!currentTime || !duration || error) return;

            let value = {
                currentTime: currentTime || 0,
                duration: duration || 0,
                percent: (currentTime / duration) || 0,
            }

            if (type == "movie") {

                setContinueData(value);

            } else if (type == "episode" && episode) {

                setContinueData(value, episode.season, episode.num);

            }

        }

    }

    const playNextEpisode = () => {

        saveToContinue();

        let { episode } = playerOptions;

        if (episode && episode.next_episode) {

            setPlayerOptions({
                ...playerOptions,
                askToContinue: true,
                episode: episode.next_episode,
            });

        }

        setSubtitles([]);
        setAudios([]);
        setSubtitle(null);
        setError(false);
        setCurrentTime(0);
        setDuration(0);
        clearTimeout(seekToTimeout);

        setCancelNextEpisode(false);
        setPlayerCurrentBlock("progressContainer");

    }

    const cancelPlayNextEpisode = () => {
        setPlayerCurrentBlock("progressContainer");
        setCancelNextEpisode(true);
    }

    const toggleControls = () => {

        clearTimeout(controlesTimeout);

        if (playerCurrentBlock == "progressContainer" || playerCurrentBlock == "buttonsContainer") {

            setControlesVisible(true);

            controlesTimeout = setTimeout(() => {
                setControlesVisible(false);
            }, 3000);

        } else {
            setControlesVisible(false);
        }

    }

    const playerBack = () => {
        saveToContinue();
        setPlayerOptions(null);
        setCurrentBlock("movie-buttons");
    }

    const openSubtitlesPopup = useCallback(() => {
        setPlayerCurrentBlock("subtitlesPopup");
    }, []);

    const openAudioPopup = useCallback(() => {
        setPlayerCurrentBlock("audioPopup");
    }, []);

    const openSettingsPopup = useCallback(() => {
        setPlayerCurrentBlock("settingsPopup");
    }, []);

    const focusProgressContainer = useCallback(() => {
        setPlayerCurrentBlock("progressContainer");
    }, []);

    const focusButtonsContainer = useCallback(() => {
        setPlayerCurrentBlock("buttonsContainer");
    }, []);

    const hidePopup = useCallback(() => {
        setPlayerCurrentBlock("progressContainer");
    }, []);

    useEffect(() => {
        toggleControls();
    }, [playerCurrentBlock])

    useEffect(() => {

        if (playerOptions) {

            let { type, askToContinue } = playerOptions; // type => "movie" | "episode" | "trailer"

            let urls = [];

            switch (type) {
                case "movie":
                    urls = playerOptions.movie?.urls || [];
                    break;
                case "episode":
                    urls = playerOptions.episode?.urls || [];
                    break;
                case "trailer":
                    urls = ["https://www.youtube.com/watch?v=" + playerOptions.movie?.trailer];
                    break;
            }

            setUrl(urls[0] || "");

            if (askToContinue && type != "trailer") {
                setIsPlaying(false);
                setPlayerCurrentBlock("continue-popup");
            } else {
                setPlayerCurrentBlock("progressContainer");
                setLoading(true);
                setIsPlaying(true);
                setIsSeeking(false);
                setIsPaused(false);
                setCancelNextEpisode(false);
            }

        } else {
            setUrl("");
            setLoading(false);
            setIsPlaying(false);
            setIsSeeking(false);
            setIsPaused(false);
            setCancelNextEpisode(false);
            setCurrentTime(0);
            setDuration(0);
            clearTimeout(seekToTimeout);
            clearTimeout(controlesTimeout);
            setSubtitles([]);
            setAudios([]);
            setSubtitle(null);
            setError(false);
        }

    }, [playerOptions])

    useKeydown({
        name: "player",
        isActive: isActive && (playerCurrentBlock == "progressContainer" || playerCurrentBlock == "buttonsContainer"),
        keydown: (e, key) => {

            toggleControls();

            if (["up", "down", "left", "right"].indexOf(key) > -1 && !controlesVisible) {
                setPlayerCurrentBlock("progressContainer");
                return true;
            }

            return false;
        },
        up: () => {
            setPlayerCurrentBlock("progressContainer");
        },
        down: () => {
            if (audios.length <= 1 && !subtitles.length) return;
            setPlayerCurrentBlock("buttonsContainer");
        },
        back: () => {
            playerBack();
        },
        play: () => {
            setIsPlaying(true);
        },
        pause: () => {
            setIsPlaying(false);
        },
        stop: () => {
            playerBack();
        },
        play_pause: () => {
            playPause();
        },
        next: () => {
            seekTo(null, currentTime + 10);
        },
        fast_next: () => {
            seekTo(null, currentTime + 30);
        },
        prev: () => {
            seekTo(null, currentTime - 10);
        },
        fast_prev: () => {
            seekTo(null, currentTime - 30);
        },
    });

    useEffect(() => {

        if (playerOptions) {
            document.body.classList.add("fullscreen");
        } else {
            document.body.classList.remove("fullscreen");
        }

    }, [playerOptions]);

    useEffect(() => {
        if (duration && playerOptions && playerOptions.percent) {
            seekTo(playerOptions.percent);
        }
    }, [duration, playerOptions]);


    if (!playerOptions || !isActive) return null;

    let title = playerOptions.movie?.name;

    if (playerOptions.type == "episode" && playerOptions.episode) {
        title += " S" + playerOptions.episode.season + " E" + playerOptions.episode.num;
    }

    const isShowingControles = (controlesVisible || isPaused) && playerCurrentBlock != "settingsPopup" && playerCurrentBlock != "continue-popup";

    return createPortal(<div className="movie-player" onClick={playPause} onMouseMove={toggleControls}>

        <AppPlayer ref={playerRef} {...playerConfign} />

        <PlayerError isVisible={error} />

        <div className={"movie-player-controller " + (isShowingControles ? "show" : "")}>

            <div className="movie-player-title">
                {title}
            </div>

            <div className="movie-player-footer">

                <ProgressContainer
                    isActive={playerCurrentBlock == "progressContainer"}
                    isPaused={isPaused}
                    currentTime={currentTime}
                    duration={duration}
                    seekTo={seekTo}
                    playPause={playPause}
                    onMouseEnter={focusProgressContainer}
                />

                <ButtonsContainer
                    isActive={playerCurrentBlock == "buttonsContainer"}
                    subtitles={subtitles}
                    audios={audios}
                    onMouseEnter={focusButtonsContainer}
                    openSubtitlesPopup={openSubtitlesPopup}
                    openAudioPopup={openAudioPopup}
                    openSettingsPopup={openSettingsPopup}
                />

            </div>

        </div>

        <PageLoading type='player' isVisible={loading && !error && playerOptions?.type != "trailer" && playerCurrentBlock != "continue-popup"} />

        <SubtitlePopup
            isVisible={playerCurrentBlock == "subtitlesPopup"}
            subtitles={subtitles}
            onBack={hidePopup}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
        />

        <SubtitleContainer
            subtitle={subtitle}
            currentTime={currentTime}
            isSeeking={isSeeking}
            isShowingControles={isShowingControles}
            isShowingSettings={playerCurrentBlock == "settingsPopup"}
        />

        <AudioPopup
            onBack={hidePopup}
            audios={audios}
            setAudios={setAudios}
            isVisible={playerCurrentBlock == "audioPopup"}
        />

        {playerCurrentBlock == "settingsPopup" ?
            <SubtitleSettings onBack={hidePopup} />
            : null}

        {playerCurrentBlock == "continue-popup" ?
            <ContinuePopup />
            : null}

        {playerCurrentBlock == "play-next-episode" ?
            <PlayNextEpisode
                confirm={playNextEpisode}
                cancel={cancelPlayNextEpisode}
                currentTime={currentTime}
                duration={duration}
            /> : null}

    </div>, document.getElementById("root"));

}

export default memo(MoviePlayer);