import Languages from "@/src/utils/languages";
import Config from "@/src/config";
import BACKEND from "./backend";

const SUBTITLES = {

    api_url: "https://api.opensubtitles.com/api/v1/",
    api_key: "XGvqL8afeCgDyrU9HR513zp8fvzxx50A", // qeshishyan
    // api_key: "dAT9W8Fx5q1nTiOYSQ5ylxLO43bDliv7", // test
    username: "qeshishyan",
    password: "Akmli6964",
    x_user_agent: Config.APP_NAME + " v" + Config.APP_VERSION,
    token: null,
    requests: [],

    abort: () => {

        clearTimeout(SUBTITLES.timeout);

        for (let i = 0; i < SUBTITLES.requests.length; i++) {

            SUBTITLES.requests[i].abort();

        }

        SUBTITLES.requests = [];

    },

    login: () => {

        let data = JSON.stringify({
            "password": SUBTITLES.password,
            "username": SUBTITLES.username,
        });

        SUBTITLES.request("login", "POST", data, (response) => {

            if (response && response.token) {
                SUBTITLES.token = response.token;
            } else {
                setTimeout(() => { SUBTITLES.login(); }, 90000);
            }

        }, () => {
            setTimeout(() => { SUBTITLES.login(); }, 90000);
        });

    },

    search: (data, cb) => {

        let page = 0;

        let url = "subtitles";
        let subtitles = [];

        let params = [];

        for (let key in data) {
            params.push(key + "=" + data[key]);
        }

        const searchSubtitles = () => {

            page++;

            let _params = [...params];

            if (page > 1) _params.push("page=" + page);

            _params.sort((a, b) => { return a > b ? 1 : -1; });

            let req_url = url + "?" + _params.join("&");

            SUBTITLES.request(req_url, "GET", {}, (response = {}) => {

                if (response.data) subtitles = [...subtitles, ...response.data];

                if (page < response.total_pages) {
                    searchSubtitles();
                } else {
                    done();
                }

            });

        }

        const done = () => {

            let obj = {};

            let order = [];

            for (let i = 0; i < subtitles.length; i++) {

                let { attributes } = subtitles[i];

                let { files, ratings } = attributes;

                let lng = attributes.language || "other";

                if (lng) lng = lng.trim();

                if (!Languages[lng]) {
                    console.log("subtitle language not found '", lng, "' using 'other'");
                    lng = "other"
                }

                if (!files || !files[0] || lng == null || !Languages[lng]) continue;

                lng = Languages[lng];

                let { file_id } = files[0];

                if (order.indexOf(lng) == -1) order.push(lng);

                if (!obj[lng]) obj[lng] = [];

                obj[lng].push({ file_id, rating: ratings, lng });

            }

            for (let key in obj) {

                obj[key].sort((a, b) => {
                    return b.rating - a.rating;
                });

            }

            for (let i = 0; i < order.length; i++) {
                order[i] = {
                    lng: order[i],
                    subtitles: obj[order[i]]
                };
            }

            cb && cb(order);

        }

        searchSubtitles();

    },

    download: (file_id, cb) => {

        BACKEND.downloadSubtitle(file_id)
            .then((response) => {
                let str_obj = SUBTITLES.parse(response);
                cb && cb(str_obj);
            })
            .catch((e) => {
                console.log(e);
            });

        return

        let data = JSON.stringify({ "file_id": file_id, "type": "srt" });

        SUBTITLES.request("download", "POST", data, function (response) {

            const xhr = new XMLHttpRequest();

            xhr.open("GET", response.link);

            xhr.onreadystatechange = function () {

                if (xhr.readyState != 4) return;

                let str_obj = SUBTITLES.parse(xhr.responseText);
                cb && cb(str_obj);

            }

            xhr.send();

            SUBTITLES.requests.push(xhr);

        });

    },

    parseTimeStr: (str) => {

        // ex: str = "00:00:00,000"

        let milliseconds = str.split(",")[1];
        milliseconds = parseInt(milliseconds);

        let [hours, minutes, seconds] = str.split(",")[0].split(":");

        hours = parseInt(hours);
        minutes = parseInt(minutes);
        seconds = parseInt(seconds);

        milliseconds += (hours * 60 * 60 * 1000);
        milliseconds += (minutes * 60 * 1000);
        milliseconds += (seconds * 1000);

        return milliseconds;

    },

    parse: (str) => {

        let lines = str.split("\n");

        let parsed = {};

        let tmp = null;

        for (let i = 0; i < lines.length; i++) {

            let line = lines[i];

            line = line.trim();

            if (line == "") {
                tmp = null;
                continue;
            }

            if (line.indexOf("-->") > -1) {

                let times = line.split("-->");

                let start = SUBTITLES.parseTimeStr(times[0].trim());
                let end = SUBTITLES.parseTimeStr(times[1].trim());

                tmp = { start, end, text: [] };

                let min = Math.ceil(start / 60000);

                if (!parsed[min]) parsed[min] = [];

                parsed[min].push(tmp);

            } else if (tmp) {
                tmp.text += line + "\n";
            }

        }

        return parsed;

    },

    request: (url, method, data, callback, error = () => { }) => {

        clearTimeout(SUBTITLES.timeout);

        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {

            if (xhr.readyState != 4) return;

            try {
                callback(JSON.parse(xhr.responseText));
            } catch (e) {
                console.log(e)
            }

        };

        xhr.onerror = (e) => {
            error(e);
            // SUBTITLES.timeout = setTimeout(() => {
            //     SUBTITLES.request(url, method, data, callback);
            // }, 500);
        };

        xhr.open(method, SUBTITLES.api_url + url);
        xhr.setRequestHeader("Api-Key", SUBTITLES.api_key);
        xhr.setRequestHeader("X-User-Agent", SUBTITLES.x_user_agent);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Accept", "application/json");

        if (SUBTITLES.token) xhr.setRequestHeader("Authorization", "Bearer " + SUBTITLES.token);

        xhr.send(data);

        SUBTITLES.requests.push(xhr);

    }

}

// SUBTITLES.login();

export default SUBTITLES;