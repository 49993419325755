import AppLogo from "./logo.png"
import SplashScreenImg from "./splashscreen.jpg";
import AppBackground from "./bg-image.jpg";
import AppBackgroundCroped from "./bg-image-croped.png";
import loginBackground from "./loginbackground.jpg"

import tvIcon from "./menu/icons/tv.png";
import moviesIcon from "./menu/icons/movies.png";
import seriesIcon from "./menu/icons/series.png";
import settingsIcon from "./menu/icons/settings.png";
// import updatePlaylistIcon from "./menu/icons/updateplaylist.png";

let Web_host = "https://power-play.io";
let Backend_host = "http://api.dm4k.live";
let Api_host = Backend_host + "/api"
let Payment_host = Web_host + "/en/purchase";
let Add_playlist_host = Web_host + "/en/upload-playlist";

const Config = {
    Web_host,
    Backend_host,
    Api_host,
    Payment_host,
    Add_playlist_host,
    AppLogo,
    SplashScreenImg,
    AppBackground,
    AppBackgroundCroped,
    loginBackground,
    Menu: {
        TV: {
            icon: <img src={tvIcon} alt="TV" />,
            down: "MOVIES",
        },
        MOVIES: {
            icon: <img src={moviesIcon} alt="Movies" />,
            down: "SERIES",
            up: "TV",
        },
        SERIES: {
            icon: <img src={seriesIcon} alt="Series" />,
            down: "SETTINGS",
            up: "MOVIES",
        },
        SETTINGS: {
            icon: <img src={settingsIcon} alt="Settings" />,
            up: "SERIES",
            // down:"UPDATEPLAYLIST"
        },
        // UPDATEPLAYLIST: {
        //     icon: <img src={updatePlaylistIcon} />,
        //     up: "SETTINGS",
        // }
    }
};

export default Config;