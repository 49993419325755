import { memo } from "react";
import Text from "../../components/text/Text";

import Button from "../../components/button/Button";
import Config from "@/src/config";
import Storege from "@/src/services/storage/storage";
import { FormatDate } from "@/src/utils/util";

const MenuItems = {
    TV: { title: "live tv", className: "tv-item", icon: Config.Menu.TV.icon },
    MOVIES: { title: "movies", className: "movies-item", icon: Config.Menu.MOVIES.icon },
    SERIES: { title: "series", className: "series-item", icon: Config.Menu.SERIES.icon },
    SETTINGS: { title: "settings", className: "settings-item", icon: Config.Menu.SETTINGS.icon },
    // UPDATEPLAYLIST: { title: "update playlist", className: "update-item", icon: Config.Menu.UPDATEPLAYLIST.icon },
}

const MenuItem = ({ name, isActive, onMouseEnter, onClick }) => {

    const { title, className, icon } = MenuItems[name];

    // const updateTime = name == "UPDATEPLAYLIST" ? Storege.getLastUpdateTime() : null;

    
    return (
        <Button
            isActive={isActive}
            className={className + " menu-item"}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            data={name}
        >

            <div className="menu-item-bg"></div>
            <div className="title"><Text>{title}</Text></div>
            {/* {
                 updateTime  && <div className="last-update-time">
                    <Text>last update</Text>: {FormatDate(updateTime,"dd/MM/yyyy hh:mm")}
                </div>
            } */}
            {icon}



        </Button>
    )

};

export default memo(MenuItem);