import { memo, useCallback, useContext, useEffect, useState } from "react";

import { categoryIcon } from "../../../assets/icons";

import CategoryItem from "../components/categoryItem/CategoryItem";
import ChannelItem from "../components/channelItem/ChannelItem";

import useKeydown from "../../../hooks/useKeydown";
import ListView from "../../../components/listView/ListView";

import { Context } from "../LiveTvPage";
import NoResult from "../components/NoResult";
import Storege from "@/src/services/storage/storage";

const ChannelsSection = () => {

    const {
        onBack,
        setCurrentSection,
        currentSection,
        channelsContent,
        setChannelsContent,
        currentChannelId,
        currentChannelsSection,
        setCurrentChannelsSection,
        currentCategoryId,
        playerOptions,
        moveItemIndex,
        setMoveItemIndex
    } = useContext(Context);

    const [initialActiveIndex, setInitialActiveIndex] = useState(0);

    const [initialCategoryActiveIndex, setInitialCategoryActiveIndex] = useState(0);

    const isActive = currentSection == "channelsSection";

    const currentCategory = channelsContent.categories_obj[currentCategoryId];

    const categoryName = currentCategory ? currentCategory.name : "";

    const channelsCount = currentCategory ? currentCategory.items.length : 0;

    const categoriesCount = channelsContent.categories_arr.length;

    const { sort } = channelsContent;

    const currentChannelIndex = currentCategory ? currentCategory.items.indexOf(currentChannelId) : null;

    useEffect(() => {
        setInitialActiveIndex(0);
    }, [currentCategoryId, sort, channelsCount]);// listen to changes channelsCount for searching 

    useEffect(() => {

        if (currentChannelsSection != "channels") return;

        if (!playerOptions.isFullscreen && currentCategory) {

            let index = currentCategory.items.indexOf(currentChannelId);

            if (index != -1) setInitialActiveIndex(index);

        }

    }, [playerOptions, currentChannelsSection]);

    useEffect(() => {

        if (currentChannelsSection != "categories") return;

        let categoryIndex = channelsContent.categories_arr.indexOf(currentCategoryId);

        if (categoryIndex != -1) {
            setInitialCategoryActiveIndex(categoryIndex);
        }

    }, [currentChannelsSection]);

    const moveFavorites = (k) => {

        if (moveItemIndex == null) return;

        if (k == 1 && moveItemIndex == currentCategory.items.length - 1) return;

        if (k == -1 && moveItemIndex == 0) return;

        let favorites = channelsContent.categories_obj.favorites.items;

        favorites.splice(moveItemIndex + k, 0, favorites.splice(moveItemIndex, 1)[0]);

        channelsContent.categories_obj.favorites.items = favorites;

        setChannelsContent({ ...channelsContent });
        setMoveItemIndex(moveItemIndex + k);
        setInitialActiveIndex(moveItemIndex + k);

        Storege.setFavorites("channels", favorites);

    }

    useKeydown({
        isActive,
        left: () => {
            setMoveItemIndex(null);
            setCurrentChannelsSection("categories");
        },
        right: () => {
            setMoveItemIndex(null);
            if (currentChannelsSection == "categories") {
                setCurrentChannelsSection("channels");
            } else {
                setCurrentSection("playerSection");
            }
        },
        up: () => {
            moveFavorites(-1);
        },
        down: () => {
            moveFavorites(1);
        },
        back: () => {
            setMoveItemIndex(null);
            if (currentChannelsSection == "categories") {
                setCurrentChannelsSection("channels");
            } else {
                onBack();
            }
        },
    });

    return (
        <div className="live-tv-section playlist-section">

            <div className="section-header focus-item">
                <img src={categoryIcon} />
                <div className="section-title">{categoryName}</div>
                <div className="section-count">{channelsCount}</div>
            </div>

            <div className="section-body">

                <div className={"section-content" + (currentChannelsSection == "categories" ? " category-section-active" : "")}>

                    <div className="category-section">
                        <div className="parent">
                            <ListView
                                id={currentChannelsSection + "-" + initialCategoryActiveIndex}
                                uniqueKey={"channel-categories-view"}
                                listType="vertical"
                                itemsTotal={categoriesCount}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={initialCategoryActiveIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={CategoryItem}
                                itemWidth={68.2}
                                itemHeight={9.5}
                                isActive={currentChannelsSection == "categories" && isActive}
                            />
                        </div>
                    </div>

                    <div className="channel-section">
                        <div className="parent">
                            {channelsCount == 0 && <NoResult />}
                            <ListView
                                id={currentChannelsSection + "-" + currentCategoryId + "-" + initialActiveIndex + "-" + sort} // 
                                uniqueKey={"channel-list-view"}
                                listType="vertical"
                                itemsTotal={channelsCount}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={initialActiveIndex}
                                onBackScrollIndex={currentChannelIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={ChannelItem}
                                itemWidth={68.2}
                                itemHeight={9.5}
                                isActive={currentChannelsSection == "channels" && moveItemIndex == null && isActive}
                            />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

};

export default memo(ChannelsSection);