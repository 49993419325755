import { memo, useEffect, useState } from "react";
import useStore from "../../../../hooks/useStore";
import "./ManageCategories.scss";
import useKeydown from "../../../../hooks/useKeydown";
import Button from "../../../../components/button/Button";
import ManageCategoriesPopup from "./popup/ManageCategoriesPopup";
import { arrowLeftBlack, arrowLeftWhite } from "../../../../assets/icons";
import Text from "../../../../components/text/Text";
import CheckPin from "@/src/components/checkPin/CheckPin";
import { SaveCategoriesConfigs } from "@/src/services/content/parser";

const ManageCategories = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    const [channelsContent] = useStore("channelsContent");

    const [moviesContent] = useStore("moviesContent");

    const [seriesContent] = useStore("seriesContent");

    const [activeIndex, setActiveIndex] = useState(0);

    const [items, setItems] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);

    const [pinIsConfirmed, setPinIsConfirmed] = useState(false);

    const sectionIsActive = isActive && !selectedItem;

    const mouseEnter = (e, index) => {
        setActiveIndex(index);
        onMouseEnter();
    };

    const onClick = (e, index) => {
        setSelectedItem(items[index]);
    };

    const onClosePopup = () => {
        setSelectedItem(null);
    };

    useEffect(() => {

        let items = [];

        if (channelsContent.categories_arr.length > 0) {
            items.push({ title: "channels categories", type: "channelsContent" });
        }

        if (moviesContent.categories_arr.length > 0) {
            items.push({ title: "movies categories", type: "moviesContent" });
        }

        if (seriesContent.categories_arr.length > 0) {
            items.push({ title: "series categories", type: "seriesContent" });
        }

        setItems(items);

    }, [channelsContent, moviesContent, seriesContent]);

    useEffect(() => {

        let timer = setTimeout(() => {

            const { categories_arr, categories_obj } = channelsContent;

            SaveCategoriesConfigs(categories_arr, categories_obj, "channels");

        }, 1000);

        return () => clearTimeout(timer);

    }, [channelsContent]);

    useEffect(() => {

        let timer = setTimeout(() => {

            const { categories_arr, categories_obj } = moviesContent;

            SaveCategoriesConfigs(categories_arr, categories_obj, "movies");

        }, 1000);

        return () => clearTimeout(timer);

    }, [moviesContent]);

    useEffect(() => {

        let timer = setTimeout(() => {

            const { categories_arr, categories_obj } = seriesContent;

            SaveCategoriesConfigs(categories_arr, categories_obj, "series");

        }, 1000);

        return () => clearTimeout(timer);

    }, [seriesContent]);

    useEffect(() => {
        if (!items.length && isActive) return onBack();
    }, [isActive]);

    useKeydown({
        isActive: sectionIsActive,
        back: onBack,
        left: () => {
            if (pinIsConfirmed) onBack();
        },
        up: () => {
            if (activeIndex > 0) setActiveIndex(activeIndex - 1);
        },
        down: () => {
            if (activeIndex < items.length - 1) setActiveIndex(activeIndex + 1);
        }
    });

    return (
        <div className="settings-content manage-categories">

            {pinIsConfirmed ?

                items.map((item, index) => {

                    const btnIsActive = activeIndex == index && sectionIsActive;

                    return (
                        <Button
                            key={index}
                            className={"manage-categories-item focus-item"}
                            isActive={btnIsActive}
                            data={index}
                            onMouseEnter={mouseEnter}
                            onClick={onClick}
                        >
                            <div><Text>{item.title}</Text></div>
                            <img src={btnIsActive ? arrowLeftBlack : arrowLeftWhite} />
                        </Button>
                    )
                })

                :

                <CheckPin
                    onMouseEnter={mouseEnter}
                    isActive={sectionIsActive}
                    onBack={onBack}
                    onLeft={onBack}
                    onComplate={() => { setPinIsConfirmed(true); }}
                />

            }

            {selectedItem ?
                <ManageCategoriesPopup
                    type={selectedItem.type}
                    title={selectedItem.title}
                    onBack={onClosePopup}
                />
                : null}
        </div>
    );

};

export default memo(ManageCategories);