import { memo, useCallback, useContext } from "react";
import Button from "@/src/components/button/Button";
import { AudioContext } from "./AudioPopup";
import Languages from "@/src/utils/languages";

const AudioItem = ({
    style,
    index,
    isActive,
    onMouseEnter,
}) => {

    const {
        audios,
        selected,
        setSelected
    } = useContext(AudioContext);

    let audio = audios[index];

    const mouseEnter = useCallback((e) => {
        onMouseEnter(index);
    }, [index]);

    const onClickItem = (e) => {

        e.stopPropagation();
        setSelected(index);

        for (let i = 0; i < audios.length; i++) {
            audios[i].enabled = i == index;
        }

    };

    if (!audio) return null;

    let title = "";

    if (audio.language) {

        let length = audio.language.length;

        if (length == 2 || length == 3) {
            title = Languages[audio.language];
        } else if (length > 3) {
            title = audio.language;
        }

    }

    if (!title) title = "Audio " + (index + 1);

    return (
        <div className="audio-item-parent" style={style}>
            <Button
                className="audio-item focus-item"
                onClick={onClickItem}
                onMouseEnter={mouseEnter}
                isActive={isActive}
                isSelected={index == selected}
            >
                {title}
            </Button>
        </div>
    )

};

export default memo(AudioItem);