import Toast from "../../components/toast/Toast";
import BACKEND from "../api/backend";
import XTREAM from "../api/xtream";
import Storege from "../storage/storage";
import storeSubscribe from "../store/subscribe";
import { ParseChannelsContent, ParseMoviesContent } from "./parser";
import { emptyContent } from "./emptyContent";

const [setChannelsContent] = storeSubscribe("channelsContent", (channelsContent) => { });

const [setMoviesContent] = storeSubscribe("moviesContent", (moviesContent) => { });

const [setSeriesContent] = storeSubscribe("seriesContent", (seriesContent) => { });

const [setStreamFormats] = storeSubscribe("streamFormats", (streamFormats) => { });

const [setPlaylistInfo] = storeSubscribe("playlistInfo", (playlistInfo) => { });



 let playlistData = {
    channels: [],
    movies: [],
    series: []
}

let retryCount = 3;
let retryTimeMS = 3000;

let xtreamLoginRetryTimeout = null;
let channelsRetryTimeout = null;
let moviesRetryTimeout = null;
let seriesRetryTimeout = null;


// get channels
export const GetPlaylistChannels = async (playlist) => {

    return new Promise((resolve, reject) => {

        clearTimeout(channelsRetryTimeout);

        let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

        if (xtream_account) {

            const getXtreamChannels = async (retry) => {

                try {

                    let channels = await XTREAM.getChannels();
                    let categories = await XTREAM.getChannelCategories();
                    let payload = await ParseChannelsContent(channels, categories, true);

                    playlistData.channels = payload;

                    setChannelsContent({ ...payload, status: "success" });
                    resolve('success')

                } catch (e) {

                    console.log(e);

                    if (retry < retryCount) {

                        console.log("channels retry", retry);

                        channelsRetryTimeout = setTimeout(() => {
                            getXtreamChannels(retry + 1);
                        }, retryTimeMS);

                    } else {
                        setChannelsContent({ ...emptyContent, status: "error" });
                        reject('error')
                    }

                }

            }

            getXtreamChannels(0);

        } else {

            BACKEND.getPlaylistData({ id: playlist.id, type: "tv" })
                .then(async ({ data, groups }) => {
                    let payload = await ParseChannelsContent(data, groups, false);
                    setChannelsContent({ ...payload, status: "success" });
                    resolve('success')
                })
                .catch((e) => {
                    console.log(e);
                    setChannelsContent({ ...emptyContent, status: "error" });
                    reject('error')
                });

        }
    })



}

// get movies
export const GetPlaylistMovies = async (playlist) => {


    return new Promise((resolve, reject) => {

        clearTimeout(moviesRetryTimeout);

        let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

        if (xtream_account) {

            const getXtreamMovies = async (retry) => {

                try {

                    let movies = await XTREAM.getMovies();

                    let categories = await XTREAM.getMovieCategories();

                    movies.sort((a, b) => {
                        return a.added > b.added ? -1 : 1;
                    });

                    // movies, categories, isSeries, isXtream
                    let payload = await ParseMoviesContent(movies, categories, false, true);
                 
                    playlistData.movies = payload;
                 
                    setMoviesContent({ ...payload, status: "success" });
                 
                    resolve('success')

                } catch (e) {

                    console.log(e);

                    if (retry < retryCount) {

                        console.log("movies retry", retry);

                        moviesRetryTimeout = setTimeout(() => {
                            getXtreamMovies(retry + 1);
                        }, retryTimeMS);

                    } else {
                        setMoviesContent({ ...emptyContent, status: "error" });
                        reject('error')
                    }

                }

            }

            getXtreamMovies(0);

        } else {

            BACKEND.getPlaylistData({ id: playlist.id, type: "movie" })
                .then(async ({ data, groups }) => {
                    let payload = await ParseMoviesContent(data, groups, false, false);// movies, categories, isSeries, isXtream
                    setMoviesContent({ ...payload, status: "success" });
                    resolve('success')
                })
                .catch((e) => {
                    console.log(e);
                    setMoviesContent({ ...emptyContent, status: "error" });
                    reject('error')
                });

        }

    })




}

// get series
export const GetPlaylistSeries = (playlist) => {


    return new Promise((resolve, reject) => {

        clearTimeout(seriesRetryTimeout);

        let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

        if (xtream_account) {

            const getXtreamSeries = async (retry) => {

                try {

                    let series = await XTREAM.getSeries();

                    let categories = await XTREAM.getSeriesCategories();

                    series.sort((a, b) => {
                        return a.last_modified > b.last_modified ? -1 : 1;
                    });

                    // movies, categories, isSeries, isXtream
                    let payload = await ParseMoviesContent(series, categories, true, true);
                   
                    playlistData.series = payload;

                    setSeriesContent({ ...payload, status: "success" });
                   
                    resolve('success')

                } catch (e) {

                    console.log(e);

                    if (retry < retryCount) {

                        console.log("series retry", retry);

                        seriesRetryTimeout = setTimeout(() => {
                            getXtreamSeries(retry + 1);
                        }, retryTimeMS);

                    } else {
                        setSeriesContent({ ...emptyContent, status: "error" });
                        reject('error')
                    }

                }

            }

            getXtreamSeries(0);

        } else {

            BACKEND.getPlaylistData({ id: playlist.id, type: "serie" })
                .then(async ({ data, groups }) => {
                    let payload = await ParseMoviesContent(data, groups, true, false);// movies, categories, isSeries, isXtream
                    setSeriesContent({ ...payload, status: "success" });
                    resolve('success')
                })
                .catch((e) => {
                    console.log(e);
                    setSeriesContent({ ...emptyContent, status: "error" });
                    reject('error')
                });

        }

    })
}

export const GetPlaylistContent = async (playlist) => {


    let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);
    


    XTREAM.abortAll();
    XTREAM.resetInfo();
    BACKEND.abortAll();
    setStreamFormats([]);

    setChannelsContent({ ...emptyContent, status: "loading" });
    setMoviesContent({ ...emptyContent, status: "loading" });
    setSeriesContent({ ...emptyContent, status: "loading" });

    clearTimeout(xtreamLoginRetryTimeout);

   

    if (xtream_account) {

        try {

            const xtream_login = async () => {

                return new Promise((resolve, reject) => {

                    const login = async (retry) => {

                        try {

                            let account = await XTREAM.login(xtream_account);
                         
                                console.log(account);
                                
                            resolve(account);

                        } catch (msg) {
                              
                         
                                
                            if(msg == 'errorLogout'){

                                if(playlist.id){

                                    await BACKEND.deletePlaylist({ id: playlist.id });

                                }
                              
                            }
                            
                            reject(msg)

                        }

                    };

                    login(0);

                });

            };

            var account = await xtream_login();
            
            setPlaylistInfo(account?.user_info);

        } catch (msg) {      

            setChannelsContent({ ...emptyContent, status: "error" });
            setMoviesContent({ ...emptyContent, status: "error" });
            setSeriesContent({ ...emptyContent, status: "error" });
            
            
            return "errorLogout"

        }

        if (account?.user_info?.allowed_output_formats) {

            let formats = account.user_info.allowed_output_formats;

            if (Array.isArray(formats) && formats.length > 0) {
                setStreamFormats(formats);
            }

        }

        XTREAM.init(xtream_account, account);
        XTREAM.stream_format = Storege.getSteamFormat();

    }

    try {

  
    
        
      await GetPlaylistData(playlist);
     
       
        return 'success'


    } catch (e) {
      

        return 'error'
    }

}


const GetPlaylistData = async (playlist) => {

    let count = 0;
  return new Promise((resolve, reject) => {

     GetPlaylistChannels(playlist).then((res) => {
        count++
        done()
        
     });

     GetPlaylistMovies(playlist).then((res) => {
        count++
        done()
        
     });

     GetPlaylistSeries(playlist).then((res) => {
        count++
        done()
        
     });


     const done = () => {


        if(count == 3){

            resolve('success')

        }

     }

  })
}