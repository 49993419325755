import { useMemo, useState } from "react";
import "./ConfirmPopup.scss";

import useKeydown from "../../hooks/useKeydown";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import { createPortal } from "react-dom";
import Config from "@/src/branding/dream-4k/Config";

const ConfirmPopup = ({
    title,
    message,
    onConfirm = () => { },
    onCancel = () => { },
    confirmText = "yes",
    cancelText = "no",
    confirmFocused = true,
}) => {

    const [isConfirmFocused, setConfirmFocused] = useState(confirmFocused);

    const left = (e) => {
        e.stopPropagation();
        setConfirmFocused(false);
    }

    const right = (e) => {
        e.stopPropagation();
        setConfirmFocused(true);
    }

    const keyDownOptions = useMemo(() => {
        return {
            capture: true,
            isActive: true,
            debounce: 300,
            left,
            right,
            up: (e) => { e.stopPropagation(); },
            down: (e) => { e.stopPropagation(); },
            back: (e) => {
                e.stopPropagation();
                onCancel();
            },
        }
    }, []);

    useKeydown(keyDownOptions);

    return createPortal(
        <div className="confirm-popup-parent bg-cover" style={{ backgroundImage: `url(${Config.AppBackground})` }}>

            <div className="confirm-popup">

                <div className="title">
                    <Text>{title}</Text>
                </div>

                <div className="message">
                    <Text>{message}</Text>
                </div>

                <div className="buttons">

                    <Button
                        className="button"
                        isActive={!isConfirmFocused}
                        onClick={onCancel}
                        onMouseEnter={() => { setConfirmFocused(false) }}
                    >
                        <Text>{cancelText}</Text>
                    </Button>

                    <Button
                        className="button"
                        isActive={isConfirmFocused}
                        activeClassName={"active-danger"}
                        onClick={onConfirm}
                        onMouseEnter={() => { setConfirmFocused(true) }}
                    >
                        <Text>{confirmText}</Text>
                    </Button>

                </div>

            </div>

        </div>, document.getElementById("root"));
}

export default ConfirmPopup;