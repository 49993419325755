import { memo, useContext } from "react";
import Button from "../../../../../components/button/Button";
import { MoviesPageContext } from "../../../MoviesPage";
import "./CategoryItem.scss";
import { LockedSVG } from "@/src/components/svgIcons";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";

const CategoryItem = ({
    isActive,
    index,
    style = {},
    onMouseEnter = () => { },
}) => {

    const {
        moviesContent,
        currentCategoryId,
        setCurrentCategoryId,
    } = useContext(MoviesPageContext);

    const categoryId = moviesContent.categories_arr[index];

    const category = moviesContent.categories_obj[categoryId];

    const mouseEnter = () => {
        onMouseEnter(index);
    };

    const onClick = (e) => {
        e.stopPropagation();
        setCurrentCategoryId(categoryId);
    };

    return (
        <div className={"movie-category-parent"} style={style}>
            <Button
                className="category-item focus-item"
                isActive={isActive}
                onMouseEnter={mouseEnter}
                onClick={onClick}
                isSelected={currentCategoryId == categoryId}
                data={index}
            >
                <div className="name">
                    <MarqueeText isActive={isActive}>{category?.name}</MarqueeText>
                </div>
                <div className="count">{category?.items.length}</div>
                <div className="lock-icon">{category?.isLocked ? <LockedSVG /> : null}</div>
            </Button>
        </div>
    )

}

export default memo(CategoryItem);