import ListView from "@/src/components/listView/ListView";
import Text from "@/src/components/text/Text";
import useContent from "@/src/hooks/useContent";
import { useState, createContext, useCallback, useEffect } from "react";
import MenuMovieItem from "./MenuMovieItem";
import useKeydown from "@/src/hooks/useKeydown";
import MovieInfo from "../movies/movieInfo/MovieInfo";
import { memo } from "react";


export const MenuMoviesContext = createContext();

const MenuContent = ({
    nativeControle = false,
    changeControlSide = () => { }
}) => {



    const [movieId, setMovieId] = useState(null);
    const [seriesId, setSeriesId] = useState(null);

    const [currentMovieId, setCurrentMovieId] = useState(null);

    const [moviesContent, setMoviesContent] = useContent("moviesContent");
    const [seriesContent, setSeriesContent] = useContent("seriesContent");

    const [startIdndex, setStartIndex] = useState(0);
    const [currentSection, setCurrentSection] = useState("movies");
    const [afterMovieInfo, setAfterMovieInfo] = useState("movies");


    const renderMovieItem = useCallback((props) => {

        let { index, type } = props;

        let itemId = type == 'movies' ? moviesContent.items_arr[index] : seriesContent.items_arr[index];

        let item = type == 'movies' ? moviesContent.items_obj[itemId] : seriesContent.items_obj[itemId];

        const handleItemClick = (id) => {

            console.log(id);
            setCurrentMovieId(id);
            setAfterMovieInfo(type);
            setCurrentSection('movieInfo')

        }

        return <MenuMovieItem
            item={item}
            isLocked={handleChecklocked(item.id, type)}
            {...props}
            onClick={handleItemClick}
            changeControlSide={changeControlSide}
            setCurrentSection={setCurrentSection}
        />

    }, [moviesContent, seriesContent])

    const handleChecklocked = (id, type) => {

        let { category_id } = type == 'movies' ? moviesContent.items_obj[id] : seriesContent.items_obj[id];

        const { isLocked } = type == 'movies' ? moviesContent.categories_obj[category_id] : seriesContent.categories_obj[category_id];

        return isLocked;

    }

    useEffect(() => {

        if (moviesContent.items_arr.length > 0 && !movieId) {
            setMovieId(1)
        }

    }, [moviesContent])

    useEffect(() => {

        if (seriesContent.items_arr.length > 0 && !seriesId) {
            setSeriesId(2)
        }

    }, [seriesContent])


    useKeydown({
        isActive: nativeControle && currentSection != 'movieInfo',
        up: () => {
            if (currentSection != 'movies') {
                setCurrentSection('movies')
            }
        },
        down: () => {
            if (currentSection != 'series') {
                setCurrentSection('series')
            }
        },
        back: () => {
            handleChangeSide()
        }
    })


    const handleChangeSide = useCallback(() => {

        changeControlSide('leftSide')
    }, [])


    return (
        <MenuMoviesContext.Provider value={
            {
                contentType: afterMovieInfo,
                afterMovieInfo,
                setCurrentMovieId,
                currentMovieId,
                setCurrentSection,
                moviesContent: afterMovieInfo == 'movies' ? moviesContent : seriesContent,
                setMoviesContent: afterMovieInfo == 'movies' ? setMoviesContent : setSeriesContent,
                isLockedMovie: handleChecklocked,
            }
        }>
            <div className="items-row">
                <div className="items-title"><Text>recently added</Text> <Text>movies</Text></div>
                <div className="scrolled-row-parent">
                    <ListView
                        uniqueKey={"movies-view-list"}
                        itemsTotal={moviesContent.items_arr.length < 15 ? moviesContent.items_arr.length : 15}
                        id={movieId}
                        itemsCount={4}
                        buffer={2}
                        initialActiveIndex={startIdndex}
                        startScrollIndex={2}
                        nativeControle={nativeControle}
                        debounce={150}
                        type={'movies'}
                        ItemRenderer={renderMovieItem}
                        itemWidth={33.3}
                        itemHeight={44.9}
                        isActive={currentSection == "movies" && nativeControle}
                        onLeft={handleChangeSide}
                    />
                </div>
            </div>
            <div className="items-row">
                <div className="items-title"><Text>recently added</Text> <Text>series</Text></div>

                <div className="scrolled-row-parent">

                    <ListView
                        uniqueKey={"series-view-list"}
                        itemsTotal={seriesContent.items_arr.length < 15 ? seriesContent.items_arr.length : 15}
                        id={seriesId}
                        itemsCount={4}
                        buffer={2}
                        initialActiveIndex={startIdndex}
                        startScrollIndex={2}
                        nativeControle={nativeControle}
                        debounce={150}
                        type={'series'}
                        ItemRenderer={renderMovieItem}
                        itemWidth={33.3}
                        itemHeight={44.9}
                        isActive={currentSection == "series" && nativeControle}
                        onLeft={handleChangeSide}
                    />

                </div>
            </div>


            {
                currentMovieId && <MovieInfo contextType="menu" />
            }
        </MenuMoviesContext.Provider>
    )
}


export default memo(MenuContent);


