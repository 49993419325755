import { memo, useCallback, useState, useEffect } from "react";

import Menu from "./Menu";
import LiveTvPage from "../liveTv/LiveTvPage";
import MoviesPage from "../movies/MoviesPage";
import PlaylistPage from "../playlist/PlaylistPage";
import SettingsPage from "../settings/SettingsPage";
import VideosPage from "../videos/VideosPage";

import "./MenuPage.scss";
import useStore from "@/src/hooks/useStore";
import LoginPopup from "@/src/popups/login/LoginPopup";
import BACKEND from "@/src/services/api/backend";
import PageLoading from "@/src/components/pageLoading/PageLoading";
import Storege from "@/src/services/storage/storage";
import { GetPlaylistContent } from "@/src/services/content/content";
// import TimeLineEpg from "../liveTv/components/timeLineEpg/TimeLineEpg";

const MenuPage = ({ isVisible, onBack = () => { },error = false }) => {

    const [_, setAppBackground] = useStore("appBackground");

    const [selectedPage, setSelectedPage] = useState("LOADING");

    const [dataError, setdataError] = useState(error);

    const [playlists, setPlaylists] = useStore("playlists");
    // menu active page needs to be parent state for save previous page on back
    const [activePage, setActivePage] = useState("TV");

    useEffect(() => {
        
          
            
        
        if (!playlists.length || dataError) {

            setSelectedPage("LOGIN");

        } else {

            checkExpiredDate();

        }

    }, [isVisible,dataError])




    const updateContent = async () => {
        
        setSelectedPage("LOADING");

        if (playlists.length) {
            let res = await GetPlaylistContent(playlists[0])   

            if(res == 'errorLogout'){
              
                setdataError(true)
            }
         }
        
         setSelectedPage("MENU");
    }


    const checkExpiredDate = async () => {

        let expiredDate = Storege.getExpiredDate();

        expiredDate = new Date(expiredDate).getTime();

        let currentDate = new Date().getTime();


        let page = 'MENU';

        if (expiredDate < currentDate) {

            page = 'LOGIN';

            for(let i = 0; i < playlists.length; i++){
                    
                    await BACKEND.deletePlaylist({ id: playlists[i].id });

            }

      

            setPlaylists([]);

            Storege.remove('selected_playlist')

        }

        setSelectedPage(page);

    }

    useEffect(() => {

        
        if(!playlists.length){

            setSelectedPage('LOGIN');

        }else{

            setSelectedPage('MENU');

        }   
        
    },[playlists])

    const showMenu = useCallback(() => {
      
        let page = !playlists.length ? 'LOGIN' : 'MENU';

        
        if(page == 'MENU'){
           
            requestAnimationFrame(() => {
                
                setAppBackground("fullscreen");
            
            });
        
        }
        
        setSelectedPage(page);
       
    }, [playlists]);

    if (!isVisible) return null;
    
    return (
        <>
            {
                selectedPage == 'LOADING' && <PageLoading isVisible={true} />
            }
            {selectedPage == "MENU" && <Menu
                onBack={onBack}
                setSelectedPage={setSelectedPage}
                activePage={activePage}
                setActivePage={setActivePage}
                updateContent={updateContent}
            />}
            {selectedPage == "TV" && <LiveTvPage onBack={showMenu} />}
            {selectedPage == "MOVIES" && <MoviesPage onBack={showMenu} type="movies" />}
            {selectedPage == "SERIES" && <MoviesPage onBack={showMenu} type="series" />}
            {selectedPage == "PLAYLISTS" && <PlaylistPage onBack={showMenu} />}
            {selectedPage == "VIDEOS" && <VideosPage onBack={showMenu} />}
            {selectedPage == "SETTINGS" && <SettingsPage onBack={showMenu} />}
            {/* {selectedPage == "ACTIVATION" && <ActivationPopup isVisible={true} onBack={showMenu} />} */}
            {selectedPage == "LOGIN" && <LoginPopup isVisible={true} onBack={()=>{setSelectedPage("MENU")}} />}
            {/* <TimeLineEpg /> */}
        </>
    )
}

export default memo(MenuPage);