import { memo, useCallback, useEffect, useMemo, useState } from "react"

import Text from "../../components/text/Text";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import Toast from "../../components/toast/Toast";

import useStore from "@/src/hooks/useStore";

import "./LoginPopup.scss";
import useKeydown from "@/src/hooks/useKeydown";
import XTREAM from "@/src/services/api/xtream";

import PlaylistLoading from "@/src/pages/playlist/playlistLoading/PlaylistLoading";
import BACKEND from "@/src/services/api/backend";

import ExitPopup from "../exitPopup/ExitPopup";

import logo from "@/src/branding/dream-4k/logo.png"
import Config from "@/src/branding/dream-4k/Config";

import arrowDown from "@/src/assets/icons/arrow-down-white.png";
import { GetPlaylistContent } from "@/src/services/content/content";
import DreamRequest from "@/src/services/api/dreamrequest";
import { parseUrlToXtreameAccount } from "@/src/utils/util";
import Storege from "@/src/services/storage/storage";
import storeSubscribe from "@/src/services/store/subscribe";


const [setPlaylists] = storeSubscribe("playlists", (playlist) => {


});

function LoginPopup({ onBack }) {

    const [activeIndex, setActiveIndex] = useState(0)
    const [focusedInput, setFocusedInput] = useState(null)
    const [loading, setLoading] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const [exitPopup, setExitPopup] = useState(false);
    const [selectDns, setSelectDns] = useState();
    const [platform] = useStore("platform");

    const [deviceInfo] = useStore("deviceInfo");


    const [dns, setDns] = useState({
        initialDns: {},
        selectDns: {},
    });

    const [inputs, setInputs] = useState([
        // { label: "dns", value: "" },
        // { label: "username", value: "" },
        { label: "activation code", value: "" }
    ])



    const onBlur = (value) => {

        setFocusedInput(null);

        inputs[activeIndex].value = value.trim();

        setInputs([...inputs])

    }

    const login = useCallback(async () => {

        let activationCode = inputs[0].value;


        if (!activationCode) return Toast.error({ msg: "activation code must be filled out", id: "login_form" });


        setLoading(true);


        if (activationCode == '44') {

            try {

                confirm({
                    host: "http://79.143.180.88:25461",
                    username: '44',
                    password: '44'
                });


            } catch (e) {

                error(e.message || 'something went wrong')

            }

            return
        }


        // for development purposes

        let sendedData = {
            mode: 'active',
            code: activationCode,
            mac: platform.mac,
            sn: platform.id,
            model: platform.model,
        }



        try {

            const result = await DreamRequest.url(sendedData);


            const { expire, err = false, message = "", url, username, password } = result;


            if (err) return error(message);


            Storege.setExpiredDate(expire);

            sendedData.mode = 'category'
            sendedData.cat_type = 'live';
            delete sendedData.model;


            confirm({
                host: url,
                username,
                password
            });

        } catch (e) {
            error(e.message || 'something went wrong')
        }



    }, [dns])




    const confirm = useCallback(async (xtream_account) => {

        const { host, username, password } = xtream_account;

        const url = `${host}/get.php?username=${username}&password=${password}&type=m3u_plus&output=ts`

        try {

            let data = await GetPlaylistContent({ url });

            if (data == 'errorLogout') {
                error("Invalid activation code");
                return
            }

            let playlist = await BACKEND.addPlaylist({ name: username, url, mac: deviceInfo.mac });

            setPlaylists([playlist]);




        } catch (e) {
            console.log(e);
            error(e);
        }

    }, [dns])

    const error = useCallback((msg) => {

        Toast.error({ msg, id: "login_form" });

        setLoading(false);

    }, [])

    const cancel = useCallback(() => {

        setLoading(false);

        XTREAM.abortAll();

        setIsCanceled(true);

    }, [])

    const onExit = useCallback(() => {

        platform.exit();

    }, [platform])

    const handleCheckInputType = ({ label, index }) => {

        if (label === 'dns') {

            setSelectDns(true)

            return
        }

        setFocusedInput(index)
    }

    const handleMouseEnter = (index) => {

        if (selectDns) return

        setActiveIndex(index)
    }




    const handleSelectDns = () => {

        setDns({ ...dns, initialDns: dns.selectDns })

        setSelectDns(false)
    }

    const keydown = useMemo(() => {

        return {
            isActive: !exitPopup && focusedInput == null && !selectDns,

            keydown: (e, key) => {
                const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

                if (activeIndex < 3 && keys.indexOf(key) > -1) {
                    inputs[activeIndex].value += key;
                    setInputs([...inputs]);

                    return true
                }
            },

            down: () => {
                if (activeIndex === 1) return;
                setActiveIndex(activeIndex + 1)
            },

            up: () => {
                if (activeIndex === 0) return;
                setActiveIndex(activeIndex - 1)
            },

            back: () => {
                if (loading) return;

                if (window.Android) {
                    onExit();
                } else {
                    setExitPopup(true);
                }
            }
        }

    }, [activeIndex, exitPopup, focusedInput, inputs, selectDns])

    useKeydown(keydown);




    return (

        <>
            {<div className="login_popup" style={{ backgroundImage: `url(${Config.loginBackground})` }}>
                <div className="login_popup_content">

                    <div className="logo">
                        <img src={logo} />
                    </div>

                    <div className="title"><Text>Login</Text></div>

                    <div className="inputs">

                        {inputs.map((input, index) => {
                            return (
                                <Button
                                    className={`button ${selectDns && input.label === 'dns' ? 'select-dns' : ''}`}
                                    key={index}
                                    data={index}
                                    isActive={activeIndex === index && !exitPopup}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onClick={() => handleCheckInputType({ ...input, index })}
                                >

                                    {
                                        input.label === 'dns' && <img src={arrowDown} className="arrow-drown" />

                                    }
                                    {
                                        input.label === 'dns' && selectDns && <img src={arrowDown} className="arrow-up" />

                                    }
                                    <Input
                                        key={index}
                                        placeholder={input.label === 'dns' ? dns.selectDns?.name : input.label}
                                        initialValue={input.value}
                                        isFocused={focusedInput === index}
                                        onBlur={onBlur}
                                        onDone={onBlur}
                                        onTop={onBlur}
                                        onBack={onBlur}
                                    />
                                </Button>
                            )
                        })}

                    </div>

                    <div className="buttons_parent">
                        <Button
                            className={"button"}
                            isActive={activeIndex === inputs.length && !exitPopup}
                            data={inputs.length}
                            onMouseEnter={() => handleMouseEnter(inputs.length)}
                            onClick={login}
                        >
                            <Text>Login</Text>
                        </Button>
                    </div>
                </div>

                {loading && <PlaylistLoading status="loading" onBack={cancel} />}
            </div>}

            {exitPopup && <ExitPopup isVisible={exitPopup} onExit={onExit} onCancel={() => setExitPopup(false)} />}

        </>

    )
}

export default memo(LoginPopup);