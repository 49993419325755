import { useContext, useEffect, useState } from "react";
import { MoviesPageContext } from "../MoviesPage";
import XTREAM from "../../../services/api/xtream";
import { FormatPlayerTime } from "../../../utils/util";
import TMDB from "../../../services/api/tmdb";
import { getYearFromMovieTitle } from "../../../utils/util"
import Storage from "../../../services/storage/storage";
import useStore from "@/src/hooks/useStore";
import { MenuMoviesContext } from "../../menu/MenuContent";

const getMovieInfo = (contextType = "movie") => {

    const [language] = useStore("language");

    const [movieInfo, setMovieInfo] = useState(null);

    const { currentMovieId, contentType, moviesContent } = useContext(contextType == 'movie' ? MoviesPageContext : MenuMoviesContext);

    const getInfo = async (callback) => {

        if (!currentMovieId || !contentType || !moviesContent) return;

        const movie = moviesContent.items_obj[currentMovieId];


        if (!movie) return;

        let { id, isXtream, name, poster, rating, url, year } = movie;

        let name_year = getYearFromMovieTitle(name);

        if (name_year.year) year = name_year.year;
        if (name_year.name) name = name_year.name;

        let movie_info = {
            id, isXtream, name, rating, year,
            backdrops: [],
            description: "",
            genre: "",
            duration: "",
            trailer: "",
        }

        let tmdbId = null;

        if (poster) movie_info.backdrops.push(poster);

        if (isXtream) {

            const getXtreamInfo = contentType == "movies" ? XTREAM.getMovieInfo : XTREAM.getSeriesInfo;

            let { info, movie_data, episodes } = await getXtreamInfo(id);

            if (info) {

                if (info.tmdb_id) tmdbId = info.tmdb_id;

                if (info.tmdb) tmdbId = info.tmdb;

                if (info.backdrop_path) {

                    if (Array.isArray(info.backdrop_path)) {
                        movie_info.backdrops = [...info.backdrop_path, ...movie_info.backdrops];
                    } else {
                        movie_info.backdrops.unshift(info.backdrop_path);
                    }

                }

                let year = info.releasedate || info.releaseDate;

                if (year) movie_info.year = year.split("-")[0];

                if (info.cover) movie_info.backdrops.push(info.cover);

                if (info.cover_big) movie_info.backdrops.push(info.cover_big);

                if (info.youtube_trailer) movie_info.trailer = info.youtube_trailer;

                movie_info.description = info.description || info.plot || info.overview || "";

                movie_info.genre = info.genre;

                if (contentType == "movies") {
                    movie_info.duration = info.duration || info.duration_secs ? FormatPlayerTime(info.duration_secs) : "";
                } else {

                    let minutes = parseInt(info.episode_run_time || "");
                    if (minutes) movie_info.duration = FormatPlayerTime(minutes * 60);

                }

            }

            if (contentType == "series") {

                if (!episodes) episodes = {};

                let seasons = {};

                let prev_episode = null;

                for (let season in episodes) {

                    if (!seasons[season]) seasons[season] = {};

                    for (let i = 0; i < episodes[season].length; i++) {

                        let { container_extension, episode_num, info, direct_source, id } = episodes[season][i];

                        let episode = {
                            season,
                            num: episode_num,
                            urls: [XTREAM.getSeriesUrl(id, container_extension)],
                            posters: []
                        };

                        if (direct_source) episode.urls.unshift(direct_source);

                        if (info) {
                            if (info.cover) episode.posters.push(info.cover);
                            if (info.cover_big) episode.posters.push(info.cover_big);
                            if (info.movie_image) episode.posters.push(info.movie_image);
                        }

                        if (prev_episode) {
                            prev_episode.next_episode = episode;
                            episode.prev_episode = prev_episode;
                        }

                        prev_episode = episode;

                        seasons[season][episode_num] = episode;

                    }

                }

                if (Object.keys(seasons).length) {
                    movie_info.seasons = seasons;
                }

            } else {
                movie_info.urls = [XTREAM.getMovieUrl(id, movie_data?.container_extension)];
            }

        } else {

            if (contentType == "movies") {
                movie_info.urls = [url];
            }

            if (contentType == "series" && movie.seasons) {

                let seasons = {};

                let prev_episode = null;

                for (const season in movie.seasons) {

                    if (!seasons[season]) seasons[season] = {};

                    for (let i = 0; i < movie.seasons[season].length; i++) {

                        let { episode, url } = movie.seasons[season][i];

                        let episode_info = {
                            season,
                            num: episode,
                            urls: [url],
                            posters: []
                        };

                        if (prev_episode) {
                            prev_episode.next_episode = episode_info;
                            episode_info.prev_episode = prev_episode;
                        }

                        prev_episode = episode_info;

                        seasons[season][episode] = episode_info;

                    }

                }

                if (Object.keys(seasons).length) {
                    movie_info.seasons = seasons;
                }

            }

        }

        if (Storage.getUseTMDB()) {

            if (!tmdbId) {

                const searchTmdb = contentType == "movies" ? TMDB.search_movies : TMDB.search_series;

                let query = { query: name };

                if (year) {

                    if (contentType == "movies") {
                        query.year = year;
                    } else {
                        query.first_air_date_year = year;
                    }

                }

                try {

                    let tmdb_info = await searchTmdb(query);

                    if (tmdb_info && tmdb_info.results && tmdb_info.results.length) tmdbId = tmdb_info.results[0].id;

                } catch (e) {
                    console.log(e);
                }

            }

            if (tmdbId) {

                movie_info.tmdbId = tmdbId;

                let getTmdbInfo = contentType == "movies" ? TMDB.get_movie_info : TMDB.get_series_info;

                let tmdb_info = null;

                try {
                    tmdb_info = await getTmdbInfo(tmdbId);
                } catch (e) {
                    console.log(e)
                }

                if (tmdb_info) {

                    if (tmdb_info.backdrop_path) {

                        let backdrop = TMDB.image_paths.original + tmdb_info.backdrop_path;

                        movie_info.backdrops.unshift(backdrop);

                    } else if (tmdb_info.poster_path) {

                        let backdrop = TMDB.image_paths.original + tmdb_info.poster_path;

                        movie_info.backdrops.unshift(backdrop);

                    }

                    if (tmdb_info.title) movie_info.name = tmdb_info.title;

                    if (tmdb_info.overview) movie_info.description = tmdb_info.overview;

                    if (tmdb_info.genres) {

                        let genres = [];

                        for (let i = 0; i < tmdb_info.genres.length; i++) {
                            genres.push(tmdb_info.genres[i].name);
                        }

                        movie_info.genre = genres.join(", ");

                    }

                    if (tmdb_info.episode_run_time) {
                        let minutes = parseInt(tmdb_info.episode_run_time[0]);
                        if (minutes) movie_info.duration = FormatPlayerTime(minutes * 60);
                    }

                    if (tmdb_info.runtime) movie_info.duration = FormatPlayerTime(tmdb_info.runtime * 60);

                    if (tmdb_info.release_date) movie_info.year = tmdb_info.release_date.split("-")[0];

                    if (tmdb_info.first_air_date) movie_info.year = tmdb_info.first_air_date.split("-")[0];

                    if (tmdb_info.vote_average) movie_info.rating = tmdb_info.vote_average;

                    if (tmdb_info.videos && tmdb_info.videos.results && tmdb_info.videos.results.length) {

                        let videos = tmdb_info.videos.results;

                        for (let i = 0; i < videos.length; i++) {

                            if (videos[i].type == "Trailer" && videos[i].site == "YouTube") {
                                movie_info.trailer = videos[i].key;
                                break;
                            }

                        }

                    }

                    if (tmdb_info.credits && tmdb_info.credits.cast && tmdb_info.credits.cast.length) {

                        let casts = [];

                        for (let i = 0; i < tmdb_info.credits.cast.length; i++) {

                            let { name, profile_path } = tmdb_info.credits.cast[i];

                            casts.push({
                                name,
                                image: TMDB.image_paths.w200 + profile_path
                            });

                        }

                        movie_info.casts = casts;

                    }

                    if (tmdb_info.translations && tmdb_info.translations.translations) {

                        let translations = tmdb_info.translations.translations;

                        for (let i = 0; i < translations.length; i++) {

                            if (translations[i].iso_639_1 == language && translations[i].data) {

                                let { title, overview } = translations[i].data;

                                if (title) movie_info.name = title;

                                if (overview) movie_info.description = overview;

                                break;

                            }

                        }

                    }

                }

            }

        }

        callback(movie_info);

    };

    useEffect(() => {

        let callback = (info) => {
            setMovieInfo(info);
        }

        getInfo(callback);

        return () => {
            callback = () => { }
        }

    }, []);

    return movieInfo;

};

export default getMovieInfo;