import { createContext, memo, useCallback, useState } from "react";
import "./SettingsPage.scss";
import ListView from "../../components/listView/ListView";
import SettingsItem from "./components/settingsItem/SettingsItem";
import ChangeLanguage from "./components/changeLanguage/ChangeLanguage";
import ChangePin from "./components/changePin/ChangePin";
import ManageCategories from "./components/manageCategories/ManageCategories";
import StreamFormat from "./components/streamFormat/StreamFormat";
import Info from "./components/info/Info";
import Text from "../../components/text/Text";
import useKeydown from "../../hooks/useKeydown";
import Storege from "../../services/storage/storage";
import ClearStorage from "./components/clearStorage/ClearStorage";
import useStore from "@/src/hooks/useStore";
import PlayerSettings from "./components/playerSettings/PlayerSettings";
import TimeSettings from "./components/timeSettings/TimeSettings";
import BACKEND from "@/src/services/api/backend";

import ConfirmPopup from "@/src/popups/confirm/ConfirmPopup";
import XTREAM from "@/src/services/api/xtream";

export const SettingsContext = createContext();

const SettingsPage = ({ onBack = () => { } }) => {

    const _items = [
        // { name: "general info", key: "info", content: Info },
        { name: "change language", key: "change_language", content: ChangeLanguage },
        { name: "change stream format", key: "stream_format", content: StreamFormat },
        { name: "change pin code", key: "change_pin", content: ChangePin },
        { name: "manage categories", key: "manage_categories", content: ManageCategories },
        { name: "clear storage", key: "clear_storage", content: ClearStorage },
        { name: "time settings", key: "time_settings", content: TimeSettings },
        { name: "use tmdb api", key: "use_tmdb_api", checked: Storege.getUseTMDB(), content: () => (null) },
        // { name: "rtl mode (for arabic)", key: "rtl_mode", checked: Storege.getRtlMode(), content: () => (null) },
    ];

    const [platform] = useStore("platform");

    const [rtlMode, setRtlMode] = useStore("rtlMode");

    const [confirmLogOut, setConfirmLogOut] = useState(false);

    const [playlists, setPlaylists] = useStore("playlists");

    if (platform?.model == "tizen" || platform?.model == "android") {

        _items.push({ name: "player settings", key: "player_settings", content: PlayerSettings })

    }

    _items.push({ name: "log out", key: "log_out", content: () => (null) })

    const [items, setItems] = useState(_items);

    const [selectedItem, setSelectedItem] = useState(0);

    const [current, setCurrent] = useState("list");

    const logOut = useCallback(async () => {

        for(let i = 0; i < playlists.length; i++){


            let name = getname(playlists[i].url);

            await platform.deleteDataFromSistem({name});
          
            await BACKEND.deletePlaylist({ id: playlists[i].id });

        }
        
      

        setPlaylists([]);

      
        Storege.remove('expiredDate')

        Storege.remove('selected_playlist')
            
        
       window.location.reload();

    }, [playlists]);

    const getname = (url) => {

        let name = ''

        let account = XTREAM.isXtreamPlaylist(url);

        if(account){

            name = account.host.split("/")

            name =  name[name.length - 1].replaceAll(".","").replaceAll(":","") 
        
            name += `${account.username}.json`

        }

        return name
            
    }

    

    const selectItem = useCallback((index) => {

        let item = items[index];

        if (item.key === "use_tmdb_api") {

            let useTmdb = item.checked;

            Storege.setUseTMDB(!useTmdb);

            item.checked = !useTmdb;

            setItems([...items]);

        } else if (item.key === "rtl_mode") {

            let rtlMode = item.checked;

            Storege.setRtlMode(!rtlMode);
            setRtlMode(!rtlMode);

            item.checked = !rtlMode;

            setItems([...items]);


        } else if (item.key == "log_out") {

            setConfirmLogOut(true);

        } else {
            if (selectedItem == index) return right();
            setSelectedItem(index);
        }

    }, [items, selectedItem]);

    const setListActive = useCallback(() => {
        setCurrent("list");
    }, []);

    const setContentActive = useCallback(() => {
        setCurrent("content");
    }, []);

    const right = () => {
        if (items[selectedItem].key == "info") return;
        setCurrent("content");
    }

    useKeydown({
        isActive: current === "list",
        right
    });

    const SelectedContent = items[selectedItem].content;

    return (
        <SettingsContext.Provider
            value={{
                items,
                setItems,
                selectedItem,
                selectItem
            }}
        >
            <div className="settings-parent">
                <div className="settings-title">
                    <Text>settings</Text>
                </div>
                <div className="settings-body">
                    <div className="settings-list-parent">
                        <ListView
                            uniqueKey={"settings-list-view"}
                            listType="vertical"
                            itemsTotal={items.length}
                            itemsCount={10}
                            buffer={0}
                            initialActiveIndex={0}
                            startScrollIndex={5}
                            nativeControle={true}
                            debounce={100}
                            ItemRenderer={SettingsItem}
                            itemWidth={62}
                            itemHeight={9}
                            isActive={current === "list"}
                            onBack={onBack}
                            onMouseEnter={setListActive}
                        />
                    </div>
                    <div className="settings-content-parent">
                        <SelectedContent
                            onBack={setListActive}
                            onMouseEnter={setContentActive}
                            isActive={current === "content"}
                        />
                    </div>
                </div>
            </div>

            {confirmLogOut && <ConfirmPopup
                title={"log out"}
                message={"are you sure you want to logout ?"}
                confirmText={"yes"}
                cancelText={"no"}
                confirmFocused={false}
                onCancel={() => {
                    setConfirmLogOut(false);
                }}
                onConfirm={() => {
                    setConfirmLogOut(false);
                    logOut();
                }}
            />}

        </SettingsContext.Provider>

    )

}

export default memo(SettingsPage);