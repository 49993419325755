import { useRef } from "react";
import { useEffect, useState, forwardRef, memo, useImperativeHandle } from "react";

let duration = 0;
let audioTracks = [];
let textTracks = [];

const VideoPlayer = (props, ref) => {

    const {
        url = "",
        mediatype = "movie",
        loop = false,
        playing = false,
        onReady = () => { },
        onPlay = () => { },
        onPause = () => { },
        onEnded = () => { },
        onBuffer = () => { },
        onBufferEnd = () => { },
        onStart = () => { },
        onError = () => { },
        onDuration = () => { },
        onProgress = () => { },
        onSeek = () => { },
        onLoadedTracks = () => { },
        playerstyle = {},
    } = props;

    const [src, setSrc] = useState(null);
    const [potocolChanged, setProtocolChanged] = useState(false);

    const videoRef = useRef(null);

    useImperativeHandle(ref, () => ({
        seekTo: (seconds) => {
            if (!seconds || !videoRef.current) return;
            videoRef.current.currentTime = seconds;
        },
        getInternalPlayer: () => {
            return videoRef.current
        }
    }));

    const play = () => {
        if (!videoRef.current) return;
        videoRef.current.play();
    }

    const pause = () => {
        if (!videoRef.current) return;
        videoRef.current.pause();
    }

    const changeProtocol = () => {

        if (!src || potocolChanged) return;

        let newSrc = src;
        let protocol = src.split("://")[0];

        if (protocol == "http") {
            newSrc = src.replace("http://", "https://");
        } else {
            newSrc = src.replace("https://", "http://");
        }

        setSrc(null);

        setProtocolChanged(true);

        setTimeout(() => {

            setSrc(newSrc);

            console.log("set new url:", newSrc);

        }, 100);

    }

    useEffect(() => {
        playing ? play() : pause();
    }, [playing]);

    useEffect(() => {

        setSrc(null);

        setProtocolChanged(false);

        let timeout = setTimeout(() => {
            setSrc(url);
            console.log("set url:", url);
        }, 100);

        return () => {
            clearTimeout(timeout);
        }

    }, [url]);

    if (!src) return null;

    return (
        <video
            width="100%"
            height="100%"
            id="videoPlayer"
            ref={videoRef}
            autoPlay={playing}
            loop={loop}
            onTimeUpdate={(e) => {
                onProgress({ played: 0, playedSeconds: e.target.currentTime, loaded: 0, loadedSeconds: 0 });
            }}
            onLoadedMetadata={() => {
                // console.log("onLoadedMetadata");
            }}
            onEnded={() => {
                // console.log("onEnded");
                onEnded();
            }}
            onPlay={() => {
                // console.log("onPlay");
                onPlay();
            }}
            onPause={() => {
                // console.log("onPause");
                onPause();
            }}
            onError={() => {
                // console.log("onError");
                if (!potocolChanged) return changeProtocol();
                onError();
            }}
            onSeeking={() => {
                // console.log("onSeeking");
            }}
            onSeeked={() => {
                // console.log("onSeeked");
                onSeek();
            }}
            onWaiting={() => {
                // console.log("onWaiting");
                onBuffer();
            }}
            onCanPlay={() => {
                // console.log("onCanPlay");
                onBufferEnd();
            }}
            onCanPlayThrough={() => {
                // console.log("onCanPlayThrough");
                onReady();
            }}
            onLoadedData={() => {
                // console.log("onLoadedData");
                onLoadedTracks();
            }}
            onDurationChange={(e) => {
                // console.log("onDurationChange", e.target.duration);
                onDuration(e.target.duration);
            }}
        >
            <source src={src} />
        </video>
    )

};

export default memo(forwardRef(VideoPlayer));