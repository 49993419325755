import { memo, useCallback, useContext } from "react";
import Button from "@/src/components/button/Button";
import ResizeImage from "@/src/components/resizeImage/ResizeImage";
import { LockedSVG } from "@/src/components/svgIcons";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";
import { dotsIcon } from "@/src/assets/icons";

const MenuMovieItem = ({
    item = {},
    index = 0,
    type = "movies",
    style = {},
    isLocked = false,
    isActive = false,
    onClick = () => { },
    onMouseEnter = () => { },
    setCurrentSection = () => { },
    changeControlSide = () => { }
}) => {

    let { id } = item || {};

    const mouseEnter = useCallback(() => {

        setCurrentSection(type)

        changeControlSide('rightSide')

        onMouseEnter(index, type);

    }, []);

    const itemClick = useCallback(() => {
        onClick(id);
    }, [id]);

    if (id == undefined || id == null) return null;


    return (
        <div className="menu-movie-item" style={style}>
            <Button
                isActive={isActive}
                className="movie-item"
                onMouseEnter={mouseEnter}
                onClick={itemClick}
            >
                <div className="icon item-bg" style={{ backgroundImage: `url(${dotsIcon})` }}>
                    <ResizeImage src={item.poster} aspectRatio={false} quality={0.5} />
                    {isLocked ? <LockedSVG /> : null}
                </div>
                {/* <div className="title">
                    <MarqueeText isActive={isActive && currentSection == "moviesSection"}>{item.name}</MarqueeText>
                </div> */}
            </Button>
        </div>
    )

};

export default memo(MenuMovieItem);

