import { useEffect, useImperativeHandle, forwardRef, memo, useState } from "react";

let isReady = false;
let duration = 0;
let isSeeking = false;
let audioTracks = [];
let textTracks = [];
let recivedTracks = false;

let voutTimeout = null;

const AndroidPlayer = (props, ref) => {

    let {
        url = "",
        mediatype = "movie",
        loop = false,
        playing = false,
        onReady = () => { },
        onPlay = () => { },
        onPause = () => { },
        onEnded = () => { },
        onBuffer = () => { },
        onBufferEnd = () => { },
        onStart = () => { },
        onError = () => { },
        onDuration = () => { },
        onProgress = () => { },
        onSeek = () => { },
        onLoadedTracks = () => { },
        playerstyle = null,
        playerType = "EXO"
    } = props;

    useImperativeHandle(ref, () => ({
        seekTo: (time) => {
            seekTo(time);
        },
        getInternalPlayer: () => {
            return {
                audioTracks: audioTracks,
                textTracks: textTracks,
            }
        }
    }));

    const [protocolChanged, setProtocolChanged] = useState(false);

    // ex. Track 1 - [English]
    const getAudioTracksLanguage = (name) => {

        if (!name) return "";

        let index = name.indexOf("[");
        let index2 = name.indexOf("]");

        if (index != -1 && index2 != -1) {
            return name.substring(index + 1, index2);
        } else {
            return name;
        }

    }

    const seekTo = (seconds) => {

        if (!seconds && seconds < 0) return;

        isSeeking = true;
        window.Android.seekTo(seconds || 0);

    }

    const play = () => {
        window.Android.play();
    }

    const pause = () => {
        window.Android.pause();
    }

    const androidPlayerTimeUpdate = () => {

        if (isSeeking) {
            onSeek();
            isSeeking = false;
            return;
        }

        let currentTime = window.Android.getCurrentTime() || 0;

        onProgress({ played: 0, playedSeconds: currentTime, loaded: 0, loadedSeconds: 0, });

        if (!duration) {
            duration = window.Android.getVideoDuration() || 0;
            if (duration) onDuration(duration);
        }

    }

    const buffering = () => {
        onBuffer();
    }

    const getAndroidTracks = () => {

        try {

            if (recivedTracks) return;

            audioTracks = [];

            var tracks = JSON.parse(window.Android.getTracks());

            if (tracks.length) recivedTracks = true;

            console.log(tracks);

            for (var i = 0; i < tracks.length; i++) {

                let track = tracks[i];

                if (track?.name?.toLowerCase() == 'disable') continue;

                switch (track?.type) {
                    case "AUDIO":

                        let index = parseInt(playerType == "EXO" ? track.track_index : track.index) || 0;
                        let group_index = parseInt(track.group_index) || 0;

                        audioTracks.push({
                            language: getAudioTracksLanguage(track.name),
                            get enabled () {
                                return track.is_selected;
                            },
                            set enabled (value) {

                                if (value == false) return;

                                for (let i = 0; i < tracks.length; i++) {
                                    if (tracks[i].type == "AUDIO") tracks[i].is_selected = false;
                                }

                                track.is_selected = true;

                                window.Android.selectTrack("AUDIO", index, group_index);

                                console.log("selectTrack", track);

                            },
                        });

                        break;
                    case "VIDEO":
                        break;
                    case "TEXT":
                        break;
                }

            }

            onLoadedTracks();


        } catch (e) {
            console.log(e);
        }

    }

    const playerError = () => {

        if (!protocolChanged) {

            setProtocolChanged(true);

            let newSrc = url;

            let protocol = url.split("://")[0];

            if (protocol == "http") {
                newSrc = url.replace("http://", "https://");
            } else {
                newSrc = url.replace("https://", "http://");
            }

            initPlayer(newSrc);

            return;
        }

        onError(new Error("Player error"));
        window.Android.destroyPlayer();

    }

    const isPlayingChanged = (isPlaying) => {

        if (!isReady) {
            playing ? play() : pause();
            isReady = true;
            return;
        }

        if (isPlaying) {
            console.log("onPlay");
            onPlay();
        } else {
            console.log("onPause");
            onPause();
        }

    }

    const vout = () => {

        onStart();

        window.Android.disableTextTrack();

        clearTimeout(voutTimeout);

        let time = mediatype == "live" ? 0 : 1000;

        voutTimeout = setTimeout(() => {
            document.body.style.backgroundColor = "transparent";
        }, time);

    }

    const streamEnd = () => {

        if (loop) {
            initPlayer(url);
        } else {
            console.log("streamEnd");
            onEnded();
        }

    }

    const destroy = () => {

        window.PLAYER = null;
        // window.Android.setPlayerPositions(0, 0, 0, 0, 0, 0);
        window.Android.destroyPlayer();

    };

    useEffect(() => {

        let { width, height, left, top } = playerstyle || {};

        if (!width) width = window.innerWidth;
        if (!height) height = window.innerHeight;
        if (!left) left = 0;
        if (!top) top = 0;

        width = parseInt(width);
        height = parseInt(height);
        left = parseInt(left);
        top = parseInt(top);

        if (width == window.innerWidth && height == window.innerHeight) {
            window.Android.setPlayerFullScreen();
            console.log("setPlayerFullScreen");
        } else {
            window.Android.setPlayerPositions(width, height, left, top, window.innerWidth, window.innerHeight);
            console.log("setPlayerPositions", width, height, left, top, window.innerWidth, window.innerHeight);
        }

    }, [playerstyle]);

    const initPlayer = (url) => {

        console.log("initPlayer", url);

        isReady = false;
        isSeeking = false;
        duration = 0;
        audioTracks = [];
        textTracks = [];
        recivedTracks = false;

        window.Android.destroyPlayer();
        console.log("destroyPlayer");

        if (!url) return;

        console.log("setPlayer", playerType);

        window.Android.setPlayer(playerType);
        window.Android.initPlayer(url, 0);

    }

    useEffect(() => {
        document.body.style.backgroundColor = playerType == "EXO" ? "transparent" : "black";
        setProtocolChanged(false);
        clearTimeout(voutTimeout);
        initPlayer(url);
    }, [url]);

    useEffect(() => {
        playing ? play() : pause();
    }, [playing]);

    useEffect(() => {

        return () => {
            destroy();
        };

    }, []);

    window.PLAYER = {
        aspect_ratio: [
            { index: 0, is_selected: true, name: "Default", type: "original", aspectRatio: 1 },
            { index: 1, is_selected: false, name: "Zoom", type: "zoom", aspectRatio: 1.7778 },
            { index: 2, is_selected: false, name: "Fixed width", type: "fixed_width", aspectRatio: 1.7778 },
            { index: 3, is_selected: false, name: "Fixed height", type: "fixed_height", aspectRatio: 1.7778 },
            { index: 4, is_selected: false, name: "Fill", type: "fill", aspectRatio: 1.7778 }
        ],
        buffering,
        streamEnd,
        androidPlayerTimeUpdate,
        getAndroidTracks,
        playerError,
        isPlayingChanged,
        vout,
    }

    return <></>

};

export default memo(forwardRef(AndroidPlayer));