import { memo, useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../../../components/button/Button";
import "./MovieButtons.scss";
import Text from "../../../../../components/text/Text";
import { MovieInfoContext } from "../../MovieInfo";
import useKeydown from "../../../../../hooks/useKeydown";
import useFavoriteMovies from "../../../../../hooks/useFavoriteMovies";

import { playWhiteIcon, playBlackIcon, plusWhiteIcon, plusBlackIcon, minusWhiteIcon, minusBlackIcon } from "../../../../../assets/icons";

const MovieButtons = ({ contextType = 'movie' }) => {

    const {
        contentType,
        movieInfo,
        currentBlock,
        setCurrentBlock,
        playMovie,
        playTrailer,
    } = useContext(MovieInfoContext);

    const isActive = currentBlock === "movie-buttons";

    const [activeButton, setActiveButton] = useState("play");

    const [isFavorite, toggelFavorite] = useFavoriteMovies(movieInfo?.id, contextType);

    const showPlayBtn = (contentType == "movies" || (movieInfo && movieInfo.seasons)) ? true : false;

    const showTrailerBtn = (movieInfo && movieInfo.trailer) ? true : false;

    const onClick = (e, name) => {

        switch (name) {
            case "play":
                playMovie();
                break;
            case "trailer":
                playTrailer();
                break;
            case "favorites":
                toggelFavorite(!isFavorite);
                break;
        }

    };

    const onMouseEnter = useCallback((e, name) => {
        setActiveButton(name);
        setCurrentBlock("movie-buttons");
    }, []);

    useEffect(() => {

        if (showPlayBtn) {
            setActiveButton("play");
        } else if (showTrailerBtn) {
            setActiveButton("trailer");
        } else {
            setActiveButton("favorites");
        }

    }, [movieInfo]);

    useKeydown({
        isActive,
        debounce: 100,
        up: () => {

            switch (activeButton) {
                case "trailer":

                    if (showPlayBtn) {
                        setActiveButton("play");
                    }

                    break;
                case "favorites":

                    if (showTrailerBtn) {
                        setActiveButton("trailer");
                    } else if (showPlayBtn) {
                        setActiveButton("play");
                    }

                    break;
            }
        },
        down: () => {

            switch (activeButton) {
                case "play":

                    if (showTrailerBtn) {
                        setActiveButton("trailer");
                    } else {
                        setActiveButton("favorites");
                    }

                    break;
                case "trailer":
                    setActiveButton("favorites");
                    break;
                case "favorites":
                    if (movieInfo && movieInfo.seasons) {
                        setCurrentBlock("movie-seasons");
                    } else if (movieInfo && movieInfo.casts) {
                        setCurrentBlock("movie-casts");
                    }
                    break;

            }

        }
    });

    return (
        <div className="movie-info-buttons">

            {showPlayBtn ? <Button
                isActive={(activeButton === "play") && isActive}
                className="movie-info-button"
                data="play"
                onClick={onClick}
                onMouseEnter={onMouseEnter}
            >
                <img src={(activeButton === "play") && isActive ? playBlackIcon : playWhiteIcon} />

                <span><Text>play</Text></span>
            </Button> : null}

            {showTrailerBtn ? <Button
                isActive={(activeButton === "trailer") && isActive}
                className="movie-info-button"
                data="trailer"
                onClick={onClick}
                onMouseEnter={onMouseEnter}
            >
                <img src={(activeButton === "trailer") && isActive ? playBlackIcon : playWhiteIcon} />

                <span><Text>play trailer</Text></span>
            </Button> : null}

            <Button
                isActive={(activeButton === "favorites") && isActive}
                className="movie-info-button"
                data="favorites"
                onClick={onClick}
                onMouseEnter={onMouseEnter}
            >
                {(activeButton === "favorites") && isActive ? <img src={isFavorite ? minusBlackIcon : plusBlackIcon} /> : <img src={isFavorite ? minusWhiteIcon : plusWhiteIcon} />}

                <span><Text>favorites</Text></span>
            </Button>

        </div>
    )
}

export default memo(MovieButtons);