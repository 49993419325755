import { memo, useCallback, useMemo } from "react";


import useStore from "@/src/hooks/useStore";
import useKeydown from "@/src/hooks/useKeydown";

import "./MenuPage.scss";
import FooterInfo from "@/src/components/footerInfo/FooterInfo";
import AppLogo from "@/src/branding/AppLogo";
import Config from "@/src/config";
import TimeLineEpg from "../liveTv/components/timeLineEpg/TimeLineEpg";
import Storege from "@/src/services/storage/storage";
import MenuItem from "./MenuItem";
import { useState } from "react";
import MenuContent from "./MenuContent";

const Menu = ({
    onBack = () => { },
    setSelectedPage = () => { },
    activePage = "TV",
    setActivePage = () => { },
    updateContent = () => { }
}) => {

    const { Menu } = Config;

    const [playlist] = useStore("playlist");
    const [deviceInfo] = useStore("deviceInfo");
    const [platform] = useStore("platform");


    const [controlType, setControlType] = useState("leftSide");

    const onMouseEnter = useCallback((e, page) => {
        setControlType("leftSide");
        setActivePage(page);
    }, []);

    const onClick = useCallback((e, page) => {

        if (page == "UPDATEPLAYLIST") return updateContent();

        setSelectedPage(page);

    }, [playlist]);

    const move = (direction) => {


        if (direction == 'right') {
            setControlType("rightSide");
            return
        }


        setActivePage(item => {

            if (Menu[item][direction]) return Menu[item][direction];

            return item;

        });

    };

   
    
    const keyDownOptions = useMemo(() => {
        return {
            isActive: controlType == 'leftSide',
            debounce: 100,
            up: () => move("up"),
            down: () => move("down"),
            // left: () => move("left"),
            right: () => move("right"),
            back: () => onBack(),
        }
    }, [controlType]);

    useKeydown(keyDownOptions);


    const menuArray = ["TV", "MOVIES", "SERIES", "SETTINGS"];

    return (
        <div className="menu-parent">

            <div className="menu-left-side">

                <AppLogo />
                <div className="menu-item-container">
                    {
                        menuArray.map((name, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    name={name}
                                    isActive={activePage == name && controlType == 'leftSide'}
                                    onMouseEnter={onMouseEnter}
                                    onClick={onClick}

                                />
                            )
                        })
                    }
                </div>

            </div>

            <div className="menu-right-side">
                <MenuContent
                    nativeControle={controlType == 'rightSide'}
                    changeControlSide={setControlType}
                />
            </div>

            <FooterInfo />

        </div>
    )

}

export default memo(Menu);