import { useCallback, useEffect, useState } from "react";
import useKeydown from "../../hooks/useKeydown";
import "./ExitPopup.scss"
import { sadIcon, smileIcon } from "../../assets/icons"
import Text from "../../components/text/Text";
import Button from "../../components/button/Button";
import Config from "@/src/branding/dream-4k/Config";

const ExitPopup = ({ isVisible, onExit = () => { }, onCancel = () => { } }) => {

    const [activeIndex, setActiveIndex] = useState(1);

    const icons = [smileIcon, sadIcon];

    const setCancelBtnActive = useCallback(() => {
        setActiveIndex(0);
    }, []);

    const setExitBtnActive = useCallback(() => {
        setActiveIndex(1);
    }, []);

    useKeydown({
        isActive: isVisible,
        debounce: 100,
        back: () => {
            onCancel();
        },
        left: () => {
            setActiveIndex(0);
        },
        right: () => {
            setActiveIndex(1);
        }
    }, [activeIndex, isVisible]);

    useEffect(() => {
        if (!isVisible) setActiveIndex(1);
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div className="exit-popup bg-cover " style={{ backgroundImage: `url(${Config.AppBackground})` }}>

            <img alt="exit" src={icons[activeIndex]} />

            <div className="exit-popup-title">
                <Text>are you sure want to exit ?</Text>
            </div>

            <div className="exit-popup-buttons">

                <Button
                    className="exit-popup-button button"
                    activeClassName="active"
                    isActive={activeIndex == 0}
                    onClick={onCancel}
                    onMouseEnter={setCancelBtnActive}
                >
                    <Text>cancel</Text>
                </Button>

                <Button
                    className="exit-popup-button button"
                    activeClassName="active-danger"
                    isActive={activeIndex == 1}
                    onClick={onExit}
                    onMouseEnter={setExitBtnActive}
                >
                    <Text>exit</Text>
                </Button>

            </div>

        </div>
    )

}

export default ExitPopup;