import { useCallback, useContext, useState } from "react";
import ListView from "../../../../components/listView/ListView";
import { MoviesPageContext } from "../../MoviesPage";
import CategoryItem from "./categoryItem/CategoryItem";
import useKeydown from "../../../../hooks/useKeydown";
import SearchButton from "./search/SearchButton";
import Search from "./search/Search";

const CategoriesSection = () => {

    const {
        currentSection,
        setCurrentSection,
        moviesContent,
        onBack,
        searchIsOpen
    } = useContext(MoviesPageContext);

    const [searchIsActive, setSearchIsActive] = useState(false);

    const categoriesCount = moviesContent.categories_arr.length;

    const isActive = currentSection == "categoriesSection";

    const onUp = useCallback(() => {
        setSearchIsActive(true);
        setCurrentSection("categoriesSection");
    }, []);

    const onDown = useCallback(() => {
        setSearchIsActive(false);
        setCurrentSection("categoriesSection");
    }, []);

    const onRight = useCallback(() => {
        setCurrentSection("moviesSection");
    }, []);

    useKeydown({
        isActive,
        debounce: 100,
        right: () => {
            if (!searchIsOpen) onRight();
        },
    });

    useKeydown({
        isActive: searchIsActive && isActive,
        down: onDown,
        back: onBack,
        right: () => {
            onRight();
        },
    });

    return (
        <div className="categories-section">
            <div className="section-header">
                <SearchButton
                    isActive={searchIsActive && isActive}
                    onMouseEnter={onUp}
                />
            </div>
            <div className="categories-parent">

                {searchIsOpen ?

                    <Search
                        onUp={onUp}
                        onRight={onRight}
                        isActive={!searchIsActive && isActive}
                        onMouseEnter={onDown}
                    />

                    :

                    <ListView
                        uniqueKey={"movies-categories-view"}
                        listType="vertical"
                        itemsTotal={categoriesCount}
                        itemsCount={13}
                        buffer={2}
                        initialActiveIndex={0}
                        startScrollIndex={5}
                        nativeControle={true}
                        debounce={100}
                        ItemRenderer={CategoryItem}
                        itemWidth={51.7}
                        itemHeight={7.5}
                        onUp={onUp}
                        isActive={!searchIsActive && isActive}
                        onBack={onBack}
                        onMouseEnter={onDown}
                    />

                }
            </div>
        </div>
    )

};

export default CategoriesSection;