import { createContext, startTransition, useContext, useEffect, useRef, useState } from "react";
import "./MovieInfo.scss";
import Casts from "./components/casts/Casts";
import Seasons from "./components/seasons/Seasons";
import MovieButtons from "./components/movieButtons/MovieButtons";
import useKeydown from "../../../hooks/useKeydown";
import { MoviesPageContext } from "../MoviesPage";
import getMovieInfo from "./getMovieInfo";
import MovieDetails from "./components/movieDetails/MovieDetails";
import Background from "./components/background/Background";
// import Player from "../player/Player";
import useContinueWatching from "../../../hooks/useContineWatching";
import PageLoading from "@/src/components/pageLoading/PageLoading";
import MoviePlayer from "../moviePlayer/MoviePlayer";
import { MenuMoviesContext } from "../../menu/MenuContent";

export const MovieInfoContext = createContext();

const MovieInfo = ({
    contextType = 'movie'
}) => {

    const {
        contentType,
        setCurrentMovieId,
        setCurrentSection,
        afterMovieInfo,
    } = useContext(contextType == 'movie' ? MoviesPageContext : MenuMoviesContext);

    const containerRef = useRef();

    const [loading, setLoading] = useState(true);

    const [currentBlock, setCurrentBlock] = useState("movie-buttons");

    const [selectedSeason, setSelectedSeason] = useState(null);

    const [seasons, setSeasons] = useState([]);

    const [episodes, setEpisodes] = useState([]);

    const [playerOptions, setPlayerOptions] = useState(null);

    const movieInfo = getMovieInfo(contextType);

    const [continueData, setContinueData] = useContinueWatching(movieInfo, contextType);

    const playMovie = (seasonNum, episodeNum) => {

        if (contentType == "series") {

            let episode = null;

            // If seasonNum and episodeNum is not null this called from the episodes list
            if (seasonNum && episodeNum) {
                episode = movieInfo.seasons[seasonNum][episodeNum]
            }

            setPlayerOptions({
                type: "episode",
                episode,
                movie: movieInfo,
                askToContinue: true,
            });

        } else {

            setPlayerOptions({
                type: "movie",
                movie: movieInfo,
                askToContinue: true,
            });

        }

        setCurrentBlock("player");

    };

    const playTrailer = () => {

        setPlayerOptions({
            type: "trailer",
            movie: movieInfo,
        });

        setCurrentBlock("player");

    };

    useEffect(() => {

        if (selectedSeason) {
            let episodes = Object.keys(movieInfo.seasons[selectedSeason]);
            setEpisodes(episodes);
        }

    }, [selectedSeason]);

    useEffect(() => {

        if (movieInfo) {

            startTransition(() => {
                setLoading(false);
            });

            if (movieInfo.seasons) {

                let seasons = Object.keys(movieInfo.seasons);

                let first = seasons[0];

                setSelectedSeason(first);

                setSeasons(seasons);

            }

        }

    }, [movieInfo]);

    useEffect(() => {

        const container = containerRef.current;

        if (container && movieInfo) {

            switch (currentBlock) {
                case "movie-buttons":
                    container.style.transform = `translateY(0)`;
                    container.style["-webkit-transform"] = `translateY(0)`; // Safari
                    break;
                case "movie-seasons":

                    if (movieInfo.casts) {
                        container.style.transform = `translateY(-46rem)`;
                        container.style["-webkit-transform"] = `translateY(-46rem)`;
                    } else {
                        container.style.transform = `translateY(-26rem)`;
                        container.style["-webkit-transform"] = `translateY(-26rem)`;
                    }

                    break;
                case "movie-casts":

                    if (movieInfo.seasons) {
                        container.style.transform = `translateY(-78rem)`;
                        container.style["-webkit-transform"] = `translateY(-78rem)`;
                    } else {
                        container.style.transform = `translateY(-26rem)`;
                        container.style["-webkit-transform"] = `translateY(-26rem)`;
                    }

                    break;
                default:
                    break;
            }

        }

    }, [currentBlock]);

    useKeydown({
        name: "movie-info",
        isActive: currentBlock != "player",
        capture: loading,
        keydown: (e, key) => {

            if (loading) e.stopPropagation();

            if (key == "back") {

                setCurrentMovieId(null);

                requestAnimationFrame(() => {

                    setCurrentSection(contextType == 'movie' ? "moviesSection" : afterMovieInfo);
                });

            }

        },
    });

    return (
        <MovieInfoContext.Provider
            value={{
                //
                currentBlock,
                setCurrentBlock,
                //
                movieInfo,
                contentType,
                //
                seasons,
                setSeasons,
                selectedSeason,
                setSelectedSeason,
                //
                episodes,
                setEpisodes,
                //
                playerOptions,
                setPlayerOptions,
                //
                playMovie,
                playTrailer,
                //
                continueData,
                setContinueData,
                //
            }}
        >

            <div className="movie-info bg-color">

                <PageLoading className="bg-color" isVisible={loading} />

                <Background />

                <div className="container" ref={containerRef}>

                    <MovieDetails />

                    <MovieButtons contextType={contextType} />

                    <Seasons />

                    <Casts />

                </div>

            </div>

            {/* <Player /> */}

            <MoviePlayer contextType={contextType} />

        </MovieInfoContext.Provider>
    );
};

export default MovieInfo;