import { memo, useCallback, useContext } from "react";
import "./EpgItem.scss";
import Button from "../../../../components/button/Button";
import { Context } from "../../LiveTvPage";
import Text from "../../../../components/text/Text";
import { DeviceDate } from "@/src/utils/util";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";

const EpgItem = ({ index, isActive, style = {}, onMouseEnter }) => {

    const {
        setCurrentSection,
        epgList,
        currentDay,
        playerOptions,
        setPlayerOptions
    } = useContext(Context);

    let epgs = epgList ? epgList[currentDay] || [] : [];

    let epg = epgs[index] || {};

    const mouseEnter = useCallback(() => {
        onMouseEnter(index);
        setCurrentSection("playerSection");
    }, [index, currentDay]);

    let now = new DeviceDate().getTime();

    let { title, description, start, end, has_archive, start_unix, end_unix } = epg;

    let status = "";

    if (now > start_unix && now < end_unix) {
        status = "live";
    } else if (now > end_unix && has_archive) {
        status = "archive";
    }

    const onClick = useCallback((e) => {

        if (window.Android && e.duration && e.duration > 500 && window.showColorButtons) {
            window.showColorButtons();
        } else {

            if (status != "archive") return;

            setPlayerOptions({
                ...playerOptions,
                isLive: false,
                epg,
                isFullscreen: true
            });

        }

    }, [index, currentDay, playerOptions]);

    let time = "--:-- : --:--";

    if (start && end) time = `${start} : ${end}`;

    if (!title) title = <Text>program not found</Text>;

    return (
        <div style={style} className="epg-item-parent">
            <Button
                className="epg-item focus-item"
                isActive={isActive}
                onClick={onClick}
                onMouseEnter={mouseEnter}
            >

                <div className={"status " + status}></div>

                <div className="title">
                    <MarqueeText isActive={isActive}>{title}</MarqueeText>
                </div>

                <div className="time">{time}</div>

            </Button>
        </div>

    )

};

export default memo(EpgItem);